import { HStack, Portal, StackProps } from '@chakra-ui/react';
import { NotificationContextType } from '@/context/Notification';
import { WalletButton } from '@/components';
import { SmarticoButton } from '@/components/Smartico/SmarticoButton';
import NotificationOverview from '@/components/Notification/User/NotificationOverview';
import React from 'react';
import { NotificationButton } from '@/components/Button/NotificationButton';

type ProfileButtonsProps = StackProps & {
  isPlaying: boolean;
  inMenu: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  handleClose?: () => void;
  openNotificationsOverview?: () => void;
  notificationContext: NotificationContextType;
  showSmarticoButton: boolean;
};
export const UserMenuButtons = ({
  isPlaying,
  inMenu,
  handleClose,
  isOpen,
  onOpen,
  onClose,
  openNotificationsOverview,
  notificationContext,
  showSmarticoButton,
  ...props
}: ProfileButtonsProps) => {
  return (
    <HStack
      position="relative"
      data-testid="catalog-tiny-info"
      justify="center"
      {...props}
      spacing={4}
    >
      <WalletButton
        target={isPlaying ? '_blank' : undefined}
        closeMenu={() => {
          if (inMenu && handleClose) handleClose();
        }}
        flex={'0 0 3.3rem'}
      />

      <NotificationButton
        notificationContext={notificationContext}
        openNotificationsOverview={openNotificationsOverview}
        lineHeight="3.3rem"
        flex={'0 0 3.3rem'}
        variant="outline"
      />

      {showSmarticoButton && (
        <SmarticoButton lineHeight="1rem" flex="0 0 3.3rem" />
      )}

      <Portal>
        <NotificationOverview />
      </Portal>
    </HStack>
  );
};
