import { useAppSettings } from '@/context/AppSettings';
import { useAuth } from '@/context/Auth';
import { useNotification } from '@/context/Notification';
import { useCurrentHost } from '@/hooks/useCurrentHost';
import { useGetLicense } from '@/hooks/useGetLicense';
import useMediaQuery from '@/hooks/useMediaQuery';
import { Portal, StackProps } from '@chakra-ui/react';
import { useMotionValue, useScroll, useTransform } from 'framer-motion';
import { PropsWithChildren, useEffect, useRef } from 'react';
import { SignInAndRegisterButtons } from '../Button/SignInButton';
import { MotionHStack } from '../Motion';
import { CatalogTinyInfo } from '../Profile/Profile_TinyInfo/CatalogTinyInfo';
import NotificationOverview from '@/components/Notification/User/NotificationOverview';

type CustomHeaderProps = StackProps & PropsWithChildren & {};

const scrollYRange = [0, 300];
let scrollThreshold = [0, 50];

const CustomHeader = ({ children, ...props }: CustomHeaderProps) => {
  const { notifToShow } = useNotification();
  const config = useAppSettings();
  const { isLogged, userData } = useAuth();
  const isSelfExcluded = userData?.status === 'self_excluded';
  const isScreenMobile = useMediaQuery('(max-width: 37.5rem)');
  const isMobile = useMediaQuery('(max-width: md)');
  const { scrollY } = useScroll();
  const height = useTransform(scrollY, scrollYRange, ['112px', '70px']);
  let scrollYOnDirectionChange = useRef(scrollY.get());
  let lastPixelsScrolled = useRef<number>(0);
  let lastScrollDirection = useRef<'down' | 'up'>();
  let pixelsScrolled = useMotionValue(0);
  let mobilePosition = useTransform(pixelsScrolled, scrollThreshold, [
    '0',
    '100px'
  ]);

  const { license } = useGetLicense();
  const currentLicense = license === 'MAIN' ? 'B' : license;
  const currentHost = useCurrentHost();
  const isHub = config.isHubEnabled && currentHost === config.domains.MAIN;

  // console.log('license: ', license);
  // console.log('currentHost: ', currentHost);
  // console.log('isHub: ', isHub);

  useEffect(() => {
    return scrollY.onChange((latest) => {
      if (latest < 0) return;

      let isScrollingDown = scrollY.getPrevious() - latest < 0;
      let scrollDirection: 'down' | 'up' = isScrollingDown ? 'down' : 'up';
      let currentPixelsScrolled = pixelsScrolled.get();
      let newPixelsScrolled;

      if (lastScrollDirection.current !== scrollDirection) {
        lastPixelsScrolled.current = currentPixelsScrolled;
        scrollYOnDirectionChange.current = latest;
      }

      if (isScrollingDown) {
        newPixelsScrolled = Math.min(
          lastPixelsScrolled?.current +
            (latest - scrollYOnDirectionChange.current),
          scrollThreshold[1]
        );
      } else {
        newPixelsScrolled = Math.max(
          lastPixelsScrolled.current -
            (scrollYOnDirectionChange.current - latest),
          scrollThreshold[0]
        );
      }

      pixelsScrolled.set(newPixelsScrolled);
      lastScrollDirection.current = scrollDirection;
    });
  }, [pixelsScrolled, scrollY]);

  const blur = useTransform(scrollY, scrollYRange, ['blur(0)', 'blur(8px)']);
  const backgroundArrayColors =
    config?.customStyle?.headerBackgroundColorsArray[currentLicense];
  const background = useTransform(scrollY, scrollYRange, backgroundArrayColors);
  const backgroundOpacity = useTransform(scrollY, scrollYRange, [0, 0.9]);
  const catalogTinyInfo = !isHub ? <CatalogTinyInfo width={'100%'} /> : null;
  const signInAndRegisterButtons = !isHub ? (
    <SignInAndRegisterButtons isCustomHeader />
  ) : (
    <SignInAndRegisterButtons
      isCustomHeader
      showLoginButton={false}
      showRegisterButton={true}
    />
  );
  return (
    <>
      <MotionHStack
        zIndex="sticky"
        left="0"
        top={notifToShow.length > 0 ? `${54.5 * notifToShow.length}px` : '0'}
        as="header"
        width="100%"
        position="fixed"
        justifyContent="space-between"
        paddingX={['1rem', '1rem', '2rem']}
        style={{
          height,
          margin: 0,
          marginTop: '0 !important',
          WebkitBackdropFilter: blur,
          backdropFilter: blur,
          background
          // backgroundColor: 'backgroundPrimaryDarker'
        }}
        {...props}
      >
        {children}
      </MotionHStack>
      {isMobile && !isSelfExcluded && (
        <MotionHStack
          className={'mobile-bottom-menu'}
          justifyContent={isLogged ? 'center' : 'space-between'}
          zIndex="sticky"
          as="header"
          width="100%"
          paddingX={['1rem', '1rem', '2rem']}
          paddingY="0.5rem"
          position="fixed"
          bottom="0"
          // background="backgroundPrimary.900"
          // overflow="hidden"

          style={{
            y: mobilePosition,
            background: backgroundArrayColors.at(1)
            // backgroundColor: 'backgroundPrimaryDarker'
          }}
        >
          {isLogged ? catalogTinyInfo : signInAndRegisterButtons}
          {/* {userData?.type === 'test' && <Badge>Test</Badge>} */}
        </MotionHStack>
      )}
      {isScreenMobile && isLogged && (
        <Portal>
          <NotificationOverview />
        </Portal>
      )}
    </>
  );
};

export default CustomHeader;
