import { HTTP } from '@/components/Http';
import LogoTestAccount from '@/components/Logo/LogoTestAccount';
import { StatusCell, Statuses } from '@/components/Table/DataTable';
import { useFormatCurrency, useFormatDate } from '@/hooks/useFormat';
import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Text,
  Th,
  Tr,
  useToast,
  VStack
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import { Transaction } from '.';
import CancelCashoutButton from '../CancelCashoutButton';

type DetailProps = {
  isOpen: boolean;
  onClose: () => void;
  row: Transaction | null;
  fetchData?: () => void;
};

const Detail = ({ isOpen, onClose, row, fetchData }: DetailProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [casinoName, setCasinoName] = useState<string>('');

  useEffect(() => {
    if (row?.requestedGamingHallId) {
      HTTP.get(`/gaming_halls/${row?.requestedGamingHallId}`)
        .then((response) => setCasinoName(response.data.name))
        .catch((err) => {
          setCasinoName('');
          toast({
            title: err?.message || 'Error',
            status: 'error',
            duration: 4000,
            position: 'top-right',
            isClosable: true
          });
        });
    }

    return () => {
      setCasinoName('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  const tableData = [
    {
      key: t('common:sent'),
      dataTestId: 'sent',
      value: useFormatDate(row?.createdAt as string)
    },
    {
      key: 'Casino',
      dataTestId: 'casino',
      value: casinoName
    },
    {
      key: 'Code',
      dataTestId: 'code',
      value: row?.code
    }
  ];

  const displayCancel = (
    ['ready_to_be_collected', 'pending'] as Statuses[]
  ).includes(row?.status as Statuses);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={['full', 'xl']}>
      <ModalOverlay />
      <ModalContent as="div" padding="1.5rem" className="blurry">
        <ModalHeader marginBottom="1rem">
          <ModalCloseButton data-testid="modal-close" />
        </ModalHeader>
        <ModalBody
          as={VStack}
          align="baseline"
          spacing="3rem"
          marginBottom="2rem"
        >
          <HStack width="100%" justifyContent="space-between">
            <LogoTestAccount />
            <VStack alignItems="flex-end" textAlign="end" maxW="10rem">
              <Text fontSize="0.8rem" color="#8E94B3">
                Transaction ID
              </Text>
              <Text data-testid="transactionID" fontSize="1rem">
                {row?.id}
              </Text>
            </VStack>
          </HStack>
          <VStack spacing="0" align="flex-start">
            <Text fontSize="0.8rem" color="#8E94B3">
              {t('common:amount')}
            </Text>
            <Text data-testid="amount" fontSize="2.25rem" fontWeight="bold">
              {useFormatCurrency({ amount: row?.amount })}
            </Text>
          </VStack>
          <VStack spacing="0.5rem" align="flex-start">
            <Text fontSize="0.8rem" color="#8E94B3">
              {t('wallet:status')}
            </Text>
            <StatusCell value={row?.status as Statuses} />
            <Text fontSize="0.8em" color="whiteAlpha.400">
              {t('wallet:averageProcessTime')}
            </Text>
          </VStack>
          <Table variant="simple">
            <Tbody>
              {tableData.map((data) => {
                if (!data.value) {
                  return null;
                }
                return (
                  <Tr key={data.key}>
                    <Th textTransform="capitalize" fontSize="1.1rem">
                      {data.key}
                    </Th>
                    <Th
                      data-testid={data.dataTestId}
                      color="white"
                      fontWeight="normal"
                      textAlign="right"
                      fontSize="1.1rem"
                    >
                      {data.value}
                    </Th>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </ModalBody>
        {displayCancel && (
          <ModalFooter justifyContent="center">
            <CancelCashoutButton
              cashout={row as Transaction}
              size="lg"
              callback={() => {
                fetchData?.();
                onClose();
              }}
              buttonTitle={t('common:cancelMyRequest')}
            />
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default Detail;
