import { toRem } from '@/helpers/toRem';
import { useCurrentHost } from '@/hooks/useCurrentHost';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Link,
  ListItem,
  UnorderedList
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import {
  ContextualNavItem,
  activeDashStyle,
  isActive
} from './ContextualLinks';
import { useAuth } from '@/context/Auth';
import { getFullUrlByLicense } from '@/utils/multiDomains';

const ContextualMainMenuItem = ({
  data,
  currentIdx
}: {
  data: ContextualNavItem[];
  currentIdx: number;
}) => {
  const currentHost = useCurrentHost();
  const { t, lang: locale } = useTranslation();
  const { isLogged } = useAuth();

  return (
    <Accordion variant="unstyled" defaultIndex={[currentIdx]}>
      {data?.map((nav) => (
        <AccordionItem key={nav.translationKey}>
          <Heading
            as="h2"
            color={
              isActive(nav.license, currentHost)
                ? '#fff'
                : 'rgba(255,255,255,.6)'
            }
            position="relative"
            marginLeft={0}
            marginBottom={toRem(16)}
            fontSize={toRem(24)}
            transition="all .3s ease"
            fontWeight={'600'}
            mb=".5rem"
            _hover={{
              color: '#fff'
            }}
          >
            <AccordionButton>
              <Box
                as="span"
                textAlign="left"
                transition="all .3s ease"
                fontSize={toRem(24)}
                fontWeight={'600'}
                position={'relative'}
                _hover={{
                  _after: {
                    ...activeDashStyle('column'),
                    opacity: 1
                  }
                }}
                _after={{
                  ...activeDashStyle('column'),
                  opacity: isActive(nav.license, currentHost) ? 1 : 0
                }}
              >
                {t(nav['translationKey'])}
              </Box>
            </AccordionButton>
          </Heading>
          {nav.itemMenu?.length ? (
            <AccordionPanel pb={4}>
              <UnorderedList m="0">
                {nav.itemMenu.map((item, index) => {
                  return !item.needAuth || item.needAuth === isLogged ? (
                    <ListItem key={index} fontSize="1.2rem">
                      <Link
                        href={
                          item.needSegment
                            ? getFullUrlByLicense(nav.license, locale) +
                              item.links
                            : getFullUrlByLicense(
                                nav.license,
                                locale,
                                false,
                                true,
                                false
                              ) + item.links
                        }
                      >
                        {t(item.translationKey)}
                      </Link>
                    </ListItem>
                  ) : null;
                })}
              </UnorderedList>
            </AccordionPanel>
          ) : null}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default ContextualMainMenuItem;
