import Favicon from 'react-favicon';
import {
  Box,
  Button,
  StackProps,
  Text,
  useDisclosure,
  useOutsideClick
} from '@chakra-ui/react';
import { CloseNotificationIcon } from '@/theme/Icons';
import { NotificationContext, UserNotification } from '@/context/Notification';
import { timeAgo } from '@/helpers/humanDate';
import getConfig from 'next/config';
import { useContext, FC, useEffect, useRef, ElementRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { HTTP } from '@/components/Http';
import useMediaQuery from '@/hooks/useMediaQuery';
import router from 'next/router';
import useResizeObserver from '@/hooks/useResizeObserver';
import { useTransform, useScroll } from 'framer-motion';
import { MotionBox } from '@/components/Motion';
import GTM from '@/helpers/googleTagManager';
import { useAppSettings } from '@/context/AppSettings';
import ExitDialog from '../ExitDialog';
import { getFallbackLanguage } from '@/helpers/lang';

type NotificationOverview = StackProps & {};

const NotificationOverview: FC<NotificationOverview> = ({}) => {
  const notificationContext = useContext(NotificationContext);
  const { lang, t } = useTranslation();
  const { publicRuntimeConfig } = getConfig();
  const appName = publicRuntimeConfig.currentAppConfig.appName;
  const isScreenMobile = useMediaQuery('(max-width: 37.5rem)');
  const dimensions = useResizeObserver();
  const { scrollY } = useScroll();
  const scrollYRange = [0, 300];
  const notificationPostions = isScreenMobile ? ['0', '0'] : ['10%', '6.5%'];
  const notificationPosition = useTransform(
    scrollY,
    scrollYRange,
    notificationPostions
  );
  const appSettings = useAppSettings();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const ref = useRef<ElementRef<'div'>>(null);
  const [currentNotification, setCurrentNotification] =
    useState<UserNotification>();
  useOutsideClick({
    enabled: !appSettings.isPlayingGame,
    ref: ref,
    handler: (e) => {
      const target = e.target as HTMLDivElement;

      // to avoid listener on all target element, add a condition to have this behavior only for fastDeposit and Notification overview.
      const idTrigger = ['modalFastDeposit', 'notification-overview'];
      const isIdTriggerExist = idTrigger.some((elm) =>
        document.querySelector(`#${elm}`)
      );

      if (target && isIdTriggerExist) {
        notificationContext.setNotificationOverviewOpened(false);

        const btn = target.closest('.chakra-button') as HTMLLinkElement;
        if (btn?.classList.contains('chakra-button')) btn.click();
      }
    }
  });

  // disable overflow scroll body on screen mobile
  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      if (notificationContext.notificationOverviewOpened && isScreenMobile) {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = '';
      }
    }
  }, [
    isScreenMobile,
    notificationContext.notificationOverviewOpened,
    dimensions
  ]);

  const markNotificationSeen = async (notificationId: string) => {
    try {
      await HTTP.patch(
        `notification/notifications/${notificationId}/mark_as_seen`,
        {}
      );
    } catch (error) {
      console.error(error);
    }
  };

  const markAllUserNotificationsAsRead = () => {
    if (notificationContext.unreadUserNotifications.length > 0) {
      markUserNotificationAsRead(notificationContext.unreadUserNotifications);
    }
  };

  const markUserNotificationAsRead = (ids: string[]) => {
    Promise.all(ids.map((id) => markNotificationSeen(id))).then(() => {
      notificationContext.loadUserNotifications();
    });
  };

  const cleanUrl = (url: string): string => {
    if (!url.match(/^https?:\/\//i)) {
      url = 'https://' + url;
    }
    return url;
  };

  const onClickNotification = (notification: UserNotification) => {
    GTM.notificationEvent('notification_click', notification.content);
    if (!notification.seenAt) markUserNotificationAsRead([notification.id]);
    if (notification.redirectTarget === '_blank') {
      window.open(cleanUrl(notification.redirectUrl), '_blank');
    } else {
      router.push(
        cleanUrl(notification.redirectUrl),
        cleanUrl(notification.redirectUrl),
        { locale: getFallbackLanguage(lang) }
      );
      // workaround for altenar sports page
      if (notification.redirectUrl.includes('#')) router.reload();
    }
    // close notification after click
    notificationContext.setNotificationOverviewOpened(false);
  };

  return (
    <>
      <Favicon
        url={`/${appName}/favicon-32x32.png`}
        iconSize={32}
        alertCount={notificationContext.unreadUserNotifications.length}
      />
      {notificationContext.notificationOverviewOpened && (
        <MotionBox
          ref={ref}
          id="notification-overview"
          data-testid="notification-overview"
          backgroundColor="backgroundPrimary.900"
          backdropBlur="0.469rem"
          borderWidth={{ base: '0', xs: '0', md: '1px' }}
          borderColor="lightWhite"
          borderStyle="solid"
          borderRadius={{ base: '0', xs: '1.25rem', md: '1.25rem' }}
          marginTop={{ base: '0', xs: '0', md: '0.5rem' }}
          marginBottom={{ base: '0', xs: '0.5rem !important', md: '0' }}
          padding="1.25rem 1rem"
          height={{ base: '100%', xs: 'auto', md: 'auto' }}
          width={{ base: '100%', xs: '370px', md: '370px' }}
          minHeight="18rem"
          position={{
            base: 'fixed',
            xs: 'fixed',
            md: 'fixed'
          }}
          left={{ base: '0', xs: '0', md: 'initial' }}
          right={{ base: '0', xs: 'initial', md: '30px' }}
          top={{
            base: '0',
            xs: 'initial',
            md: notificationPostions
          }}
          bottom={{ base: 'initial', xs: '115%', md: 'initial' }}
          zIndex="99999999"
          style={{
            top: notificationPosition
          }}
        >
          <Box
            className="notification-header"
            display="flex"
            alignItems="center"
            position="relative"
            marginBottom="1rem"
          >
            <Text fontWeight="bold" fontSize="1.1rem">
              {t('notifications:notifications')}
            </Text>
            <CloseNotificationIcon
              marginLeft="auto"
              cursor="pointer"
              onClick={() =>
                notificationContext.setNotificationOverviewOpened(false)
              }
            />
          </Box>
          {notificationContext.userNotifications &&
          notificationContext.userNotifications.length > 0 ? (
            <>
              <Box
                data-testid="notification-list"
                className="notification-list"
                maxHeight={['80vh', '30rem', '30rem']}
                overflow="hidden auto"
              >
                {notificationContext.userNotifications.map(
                  (notification: UserNotification) => (
                    <Box
                      key={notification.id}
                      background={
                        !notification.seenAt ? 'brand.200' : 'customDarkGrey'
                      }
                      borderColor="buttonPrimary"
                      borderStyle="solid"
                      borderWidth={notification.seenAt ? '0' : '1px'}
                      borderRadius="1.25rem"
                      cursor="pointer"
                      display="flex"
                      maxWidth="100%"
                      marginTop="0.625rem"
                      className="notification"
                      minWidth="20rem"
                      width="100%"
                      padding="0.75rem"
                      onClick={() => {
                        if (appSettings.isPlayingGame) {
                          onOpen();
                          setCurrentNotification(notification);
                          appSettings.setTriggerCloseGame(false);
                        } else {
                          onClickNotification(notification);
                        }
                      }}
                    >
                      <Box
                        flexGrow="0"
                        flexShrink="0"
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        w={12}
                      >
                        <Box
                          as="span"
                          display="inline-block"
                          height="0.625rem"
                          width="0.625rem"
                          background={
                            !notification.seenAt
                              ? 'customRed'
                              : 'customGrey.200'
                          }
                          borderRadius="50%"
                        ></Box>
                      </Box>
                      <Text
                        fontSize="0.938rem"
                        fontFamily="Galano"
                        fontWeight="700"
                        flexGrow="1"
                        flexShrink="1"
                      >
                        <span data-testid="notification-item-content">
                          {notification.content}
                        </span>
                        <Text
                          as="span"
                          fontSize="0.688rem"
                          fontWeight="500"
                          display="block"
                        >
                          {timeAgo(new Date(notification.sentAt), lang)}
                        </Text>
                      </Text>
                    </Box>
                  )
                )}
              </Box>
              <Box
                className="notification-footer"
                width="100%"
                marginTop="1.75rem"
                marginBottom="1rem"
                textAlign="center"
              >
                <Button
                  display="inline-block"
                  color="buttonSecondary"
                  textDecoration="underline"
                  bg="none"
                  boxShadow="none"
                  cursor="pointer"
                  fontSize="0.938rem"
                  fontWeight="500"
                  _hover={{
                    background: 'none',
                    color: 'buttonPrimary'
                  }}
                  border="none"
                  outline="0"
                  minWidth="inherit"
                  padding="0"
                  borderRadius="0"
                  height="auto"
                  lineHeight="normal"
                  verticalAlign="middle"
                  onClick={() => {
                    markAllUserNotificationsAsRead();
                  }}
                >
                  {t('notifications:markAllAsRead')}
                </Button>
              </Box>
            </>
          ) : (
            <Box
              minWidth="20rem"
              height="10rem"
              textAlign="center"
              lineHeight="10rem"
            >
              {t('notifications:noNotifications')}
            </Box>
          )}
        </MotionBox>
      )}
      <ExitDialog
        isDialogOpen={isOpen}
        alertOnAccept={() => {
          onClickNotification(currentNotification as UserNotification);
          setCurrentNotification(undefined);
          onClose();
          appSettings.setTriggerCloseGame(true);
          notificationContext.setNotificationOverviewOpened(false);
        }}
        alertOnDenied={() => {
          onClose();
          appSettings.setTriggerCloseGame(false);
        }}
      />
    </>
  );
};

export default NotificationOverview;
