import {
  Box,
  HStack,
  Heading,
  StackProps,
  Tooltip,
  useTheme
} from '@chakra-ui/react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { MotionProps } from 'framer-motion';
import { FC, PropsWithChildren, useState } from 'react';
import FavoriteButton from '../Button/FavoriteButton';
import PlayNowButton from '../Button/PlayNowButton';
import { MotionVStack } from '../Motion';
import { GameTitle, GameSubTitle } from './Artefacts';
import GameImage from './GameImage';
import TagList from './TagList';
import { useCatalog } from '@/context/Catalog';
import { toRem } from '@/helpers/toRem';
import { GameCatalogItem } from '@/services/getCatalog';
import { getTournamentsUrl } from '@/helpers/tournaments';
import useTranslation from 'next-translate/useTranslation';
import { LicenceType } from '@/utils/multiDomains';

type GameImageAndTitleProps = PropsWithChildren &
  MotionProps &
  StackProps & {
    game: GameCatalogItem;
    section?: string;
    tournament?: string;
    fullWidth?: boolean;
    isFavorites?: boolean;
    imgSize?: number;
    nbOfImages?: number;
    index?: number;
    inOneLine?: boolean;
    isHybridBreakpoint?: boolean;
    variant?: string;
  };

export enum GameImageAndTitleVariants {
  DEFAULT = 'default',
  CMS = 'cms'
}

const GameImageAndTitle: FC<GameImageAndTitleProps> = ({
  game,
  section,
  tournament,
  fullWidth = false,
  isFavorites = false,
  imgSize,
  nbOfImages,
  index,
  children,
  variant = GameImageAndTitleVariants.DEFAULT,
  ...props
}: GameImageAndTitleProps) => {
  // const { isTournamentEnabled } = useAppSettings();
  const { dataStore, licence /* gamesAvailableInTournaments */ } = useCatalog();
  const { t, lang } = useTranslation();
  const theme = useTheme();

  // const showHighlightTournament = isTournamentEnabled && gamesAvailableInTournaments?.has(game.id);

  let highlightTournamentUrl = getTournamentsUrl({
    locale: lang,
    license: licence as LicenceType
  });

  // if (
  //   showHighlightTournament &&
  //   isTournamentEnabled &&
  //   gamesAvailableInTournaments
  // ) {
  //   const tournaments = gamesAvailableInTournaments?.get(game.id);
  //   if (tournaments?.length === 1) {
  //     highlightTournamentUrl = getTournamentUrl({
  //       locale: lang,
  //       license: licence as LicenceType,
  //       id: tournaments[0].id,
  //       slug: tournaments[0].slug
  //     });
  //   }
  // }

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const getProviderByIri = (iri: string) => dataStore?.providers?.[iri]?.name;

  let defaultVariantStyles;
  if (variant === GameImageAndTitleVariants.DEFAULT) {
    defaultVariantStyles = {
      marginBottom: ['1.9rem']
    };
  }
  const isMobile = useMediaQuery('(max-width: 599px)');
  const isCMSVariant = variant === GameImageAndTitleVariants.CMS;
  let cmsVariantStyles;
  if (isCMSVariant) {
    cmsVariantStyles = {
      boxShadow: ['none', '0 10px 34px #000000A3'],
      overflow: !isMobile ? 'hidden' : undefined,
      borderBottomRadius: !isMobile ? toRem(20) : undefined,
      borderTopRadius: !isMobile ? toRem(10) : undefined
    };
  }

  const borderRadius = isCMSVariant
    ? isMobile
      ? toRem(20)
      : `${toRem(10)} ${toRem(10)} 0 0`
    : '1.875rem';

  return (
    <MotionVStack
      key={game.id || game?.publicId}
      width="100%"
      style={{ transition: 'all .3s ease' }}
      height="auto"
      {...props}
      {...defaultVariantStyles}
      {...cmsVariantStyles}
    >
      <GameImage
        // href={`/games/${game.id || game?.publicId}/${game.slug}`}
        data-testid={game.id}
        src={game?.mediaUrls?.catalog_thumbnail as string}
        game={game}
        height={148}
        width={223}
        style={{ width: '100%', aspectRatio: 223 / 148 }}
        withoutBackground={isCMSVariant}
        borderRadius={borderRadius}
        stackProps={{
          width: isCMSVariant ? '100%' : undefined
          // _before: {
          //   content: showHighlightTournament ? `""` : undefined,
          //   borderRadius: '1.875rem',
          //   position: 'absolute',
          //   inset: 0,
          //   boxShadow: `0 0 0 4px ${theme.colors?.tournament?.available?.borderColor} inset`
          // }
        }}
      >
        <HStack
          position="absolute"
          bottom={'0'}
          width="100%"
          justifyContent="space-between"
          paddingX="0.8rem"
          height={'100%'}
          borderRadius={borderRadius}
          overflow={'hidden'}
          onMouseOver={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
        >
          <Box
            bgColor={'rgba(0,0,0,.8)'}
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            transition="all .3s ease"
            transform={isHovered ? 'translateY(0%)' : 'translateY(100%)'}
            padding=".5rem"
            display={'flex'}
            justifyContent="center"
            flexFlow={'column'}
            borderRadius={borderRadius}
          >
            <GameTitle noOfLines={1} textAlign="center" fontSize={toRem(15)}>
              <Tooltip label={game.name}>
                <span>{game.name}</span>
              </Tooltip>
            </GameTitle>
            <GameSubTitle
              marginBottom={'.5rem'}
              textAlign="center"
              fontSize={toRem(13)}
            >
              {getProviderByIri(game?.provider as string)}
            </GameSubTitle>

            <PlayNowButton
              section={section}
              games={game}
              callback={() => {
                setIsHovered(false);
              }}
            />
            <Box position={'absolute'} right={'10px'} bottom={'5px'}>
              <FavoriteButton fontSize="xl" game={game} section={section} />
            </Box>
          </Box>
        </HStack>
      </GameImage>
      {/* <HStack
        fontSize="sm"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        color="rgba(255,255,255,0.6)"
        style={{ marginTop: '1rem' }}
      >
        <GameTitle noOfLines={1}>
          {getLocaleTranslation(game?.translations, lang)?.name
            ? getLocaleTranslation(game?.translations, lang)?.name
            : game.name}
        </GameTitle>
        <TextWithIcon spacing={0} icon={EyeIcon} text={game?.launchCount} />
        {isFavorites ? (
          <TextWithIcon text={game.favoritesCount} icon={HeartIcon} />
        ) : (
          <TextWithIcon spacing={0} icon={VitalIcon} text={game?.volatility} />
        )}
      </HStack> */}

      {/* {showHighlightTournament ? (
        <Box
          fontSize={toRem(14)}
          lineHeight={toRem(18)}
          py="2"
          alignSelf={'stretch'}
          fontWeight={500}
          flex={'1 1 auto'}
          color={'tournament.available.color'}
          mt={'0.5rem!'} // use line-height for creating space (mt: 16px / mb:8) -> so link has a min height of 34px for mobile
          mb={'-1.25rem!'} // due to the margin on taglist
        >
          <Link href={highlightTournamentUrl}>
            {t('common:availableTournament')}
          </Link>
        </Box>
      ) : null} */}

      {variant === GameImageAndTitleVariants.CMS && game.name ? (
        <Box
          w="full"
          bg={['transparent', 'customDarkGreyBackground.400']}
          pt={[0, '4!']}
          mt={['4!', '0!']}
          maxWidth={'100%'}
        >
          <Heading
            as="h5"
            fontSize={[toRem(18), toRem(18)]}
            lineHeight={[toRem(24), toRem(24)]}
            fontWeight={500}
            textAlign={['left', 'center']}
            marginBottom={['calc(-1.25rem + 4px)', 4]} // due to style on TagList
            overflow={'hidden'}
            whiteSpace={'nowrap'}
            textOverflow={'ellipsis'}
            px={[0, 4]}
            title={game.name}
          >
            {game.name}
          </Heading>
        </Box>
      ) : null}

      {variant === GameImageAndTitleVariants.DEFAULT || isMobile ? (
        <TagList height="auto" game={game} minNumberOfTags={2} />
      ) : null}

      {children}
    </MotionVStack>
  );
};

export default GameImageAndTitle;
