import { toRem } from '@/helpers/toRem';
import { useCurrentHost } from '@/hooks/useCurrentHost';
import {
  DomainLicense,
  getFullUrlByLicense,
  getLicenseByHost,
  getHubHomeLinkByLicense
} from '@/utils/multiDomains';
import { Box, Text, Link } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useAppSettings } from '@/context/AppSettings';
export type ContextualNavItem = {
  translationKey: string;
  itemMenu: {
    translationKey: string;
    links: string;
    needAuth: boolean;
    needSegment: boolean;
    active: boolean;
  }[];
  license: keyof DomainLicense;
};
export type ContextualLinksPosition = 'row' | 'column';
export type ContextualLinksTypes = {
  data: ContextualNavItem[];
  hallsUrl: string;
  position?: ContextualLinksPosition;
  showMainNavItem?: boolean;
};

export const activeDashStyle = (position: ContextualLinksPosition) => ({
  content: "''",
  position: 'absolute',
  bottom: position === 'row' ? '-10px' : '-5px',
  left: position === 'row' ? '50%' : 0,
  transform: position === 'row' ? 'translateX(-50%)' : 'none',
  width: position === 'row' ? '22px' : '100%',
  height: '3px',
  borderRadius: '5px',
  backgroundColor: 'brand.1000'
});

export const isActive = (license: keyof DomainLicense, currentHost: string) =>
  getLicenseByHost(currentHost) === license;

const ContextualLinks = ({
  data,
  hallsUrl,
  position = 'row',
  showMainNavItem = true
}: ContextualLinksTypes) => {
  const router = useRouter();
  const { t, lang: locale } = useTranslation();
  const currentHost = useCurrentHost();
  const appSettings = useAppSettings();

  const buildUrl = (url: string) => {
    return url.startsWith('/')
      ? getFullUrlByLicense('MAIN', locale) + url.slice(1)
      : url;
  };

  const isActive = (license: keyof DomainLicense) =>
    getLicenseByHost(currentHost) === license;

  return (
    <Box
      marginLeft={position === 'row' ? toRem(31) : 0}
      display="flex"
      flexDirection={position}
    >
      {showMainNavItem
        ? data?.map((nav) => {
            return (
              <Link
                key={nav.license}
                href={getHubHomeLinkByLicense({
                  license: nav.license,
                  locale,
                  appSettingsFeatures: appSettings?.features
                })}
                marginLeft={position === 'row' ? toRem(31) : 0}
                marginBottom={position === 'column' ? toRem(16) : 0}
                position="relative"
                fontWeight={'600'}
                fontSize={position === 'row' ? toRem(18) : toRem(24)}
                transition="all .3s ease"
                color={isActive(nav.license) ? '#fff' : 'rgba(255,255,255,.6)'}
                _hover={{
                  color: '#fff'
                }}
              >
                <Box
                  as="span"
                  position={'relative'}
                  _hover={{
                    _after: {
                      ...activeDashStyle(position),
                      opacity: 1
                    }
                  }}
                  _after={{
                    ...activeDashStyle(position),
                    opacity: isActive(nav.license) ? 1 : 0
                  }}
                >
                  {t(nav.translationKey)}
                </Box>
              </Link>
            );
          })
        : null}
      {hallsUrl ? (
        <Text
          as="a"
          href={buildUrl(hallsUrl)}
          marginLeft={position === 'row' ? toRem(31) : 0}
          marginBottom={position === 'column' ? toRem(16) : 0}
          fontWeight={'600'}
          fontSize={position === 'row' ? toRem(18) : toRem(24)}
          transition="all .3s ease"
          color={
            router.pathname === '/halls'
              ? 'rgba(255,255,255,1)'
              : 'rgba(255,255,255,.6)'
          }
          _hover={{
            color: '#fff'
          }}
        >
          <Box
            as="span"
            position={'relative'}
            _hover={{
              _after: {
                ...activeDashStyle,
                opacity: 1
              }
            }}
            _after={{
              ...activeDashStyle,
              opacity: router.pathname === '/halls' ? 1 : 0
            }}
          >
            {t('common:navHalls')}
          </Box>
        </Text>
      ) : null}
    </Box>
  );
};
ContextualLinks.getInitialProps = async () => {
  return {};
};
export default ContextualLinks;
