import { useAppSettings } from '@/context/AppSettings';
import { useExtraProps } from '@/pages/_app';
import { getFullUrlByLicense } from '@/utils/multiDomains';
import { Box, Img, TabsProps, Text } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { ReactElement, RefObject } from 'react';
import { MotionBox, bottomUPChildrenElementAnimation } from '../Motion';
import Slider from '../Slider/Slider';
import { Category, Licence } from './types';
import { getTournamentsUrl } from '@/helpers/tournaments';

type CategoriesProps = TabsProps & {
  data: {
    [key: string]: Category;
  };
  observeRef: RefObject<HTMLDivElement>;
  children?: ReactElement | null;
  activeSlug?: string;
};

const Categories = ({
  data,
  observeRef,
  children,
  activeSlug,
  ...props
}: CategoriesProps) => {
  const config = useAppSettings();
  const { lang, t } = useTranslation();
  const { license } = useExtraProps();
  const router = useRouter();
  const categories = Object.values(data)
    .filter(
      (category) =>
        category.licenses.includes(`${license}+` as Licence) && category.visible
    )
    ?.sort((a, b) => a?.order - b?.order);

  const categoryItems: CategoryItem[] = [
    {
      id: 'all',
      name: t('common:all'),
      slug: 'all',
      url: getFullUrlByLicense(license, lang),
      img: '/icons/all.svg'
    },
    ...categories.map((category) => ({
      ...category,
      url: `${getFullUrlByLicense(license, lang)}/category/${category.slug}`,
      img: category?.mediaUrls?.icon as unknown as string
    }))
  ];

  if (config.isTournamentEnabled) {
    categoryItems.push({
      id: 'TOURNAMENTS',
      name: t('catalog:tournaments'),
      slug: 'TOURNAMENTS',
      url: getTournamentsUrl({
        locale: lang,
        license
      }),
      img: '/icons/tournaments.svg'
    });
  }

  const currentSlug = activeSlug ?? router?.query?.slug ?? 'all';
  const activeItemColor = 'whiteAlpha.400';
  const itemStyle = {
    minW: 'fit-content',
    position: 'relative',
    fontSize: '1rem',
    fontWeight: '500',
    color: 'white',
    cursor: 'pointer',
    border: '1px solid',
    borderColor: 'whiteAlpha.400',
    borderRadius: '1rem',
    textAlign: 'center',
    backgroundColor: 'rgba(43, 43, 43, 0.3)',
    flexDirection: 'column',
    _hover: {
      backgroundColor: activeItemColor,
      borderColor: 'transparent'
    },
    _selected: {
      backgroundColor: activeItemColor,
      borderColor: 'transparent'
    }
  };

  return categoryItems?.length ? (
    <Slider>
      {categoryItems?.map((categoryItem) => (
        <MotionBox
          as="li"
          key={categoryItem.id}
          data-testid={categoryItem.name}
          variants={bottomUPChildrenElementAnimation}
          _focusVisible={{ boxShadow: 'none' }}
          {...itemStyle}
          backgroundColor={
            categoryItem.slug === currentSlug
              ? activeItemColor
              : itemStyle.backgroundColor
          }
          width="auto"
          height="auto"
        >
          <Box
            height={['5.38rem', '5.38rem', '6.25rem']}
            width={['6.45rem', '6.45rem', '7.51rem']}
            position="relative"
            style={{ aspectRatio: '1.2 / 1' }}
          >
            <Box
              as="a"
              href={categoryItem.url}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              width={'100%'}
              height={'100%'}
              position={'absolute'}
              top={0}
              left={0}
            >
              <Img
                src={categoryItem.img}
                alt={categoryItem.name}
                width="28"
                height="28"
                marginBottom=".3rem"
                style={{ width: '28px', height: '28px' }}
              />
              <Text
                fontWeight="400"
                fontSize="0.9375rem"
                lineHeight="normal"
                padding="0 0.5rem"
                width="99%"
              >
                {categoryItem.name}
              </Text>
            </Box>
          </Box>
        </MotionBox>
      ))}
    </Slider>
  ) : null;
};

export default Categories;

interface CategoryItem {
  id: string;
  name: string;
  slug: string;
  url: string;
  img: string;
}
