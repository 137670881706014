import { toRem } from '@/helpers/toRem';
import {
  Button,
  CircularProgress,
  HStack,
  TextProps,
  Tooltip,
  VStack
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { GameImage, GameSubTitle, GameTitle, ScrollWithoutScrollBar } from '.';
import NoGameFallback from '../Fallback/NoGameFallback';
import {
  bottomUPChildrenElementAnimation,
  leftToRightChildrenElementAnimation,
  MotionHStack,
  MotionText,
  MotionVStack
} from '../Motion';
import BannerTitleAndImage from './BannerTitleAndImage';
import { HTTP } from '../Http';
import { BannerType } from '@/types/api/ge-strapi/banner';
import { getFallbackLanguage } from '@/helpers/lang';
import { useExtraProps } from '@/pages/_app';
import { isValidUrl, routeToUrl } from '@/helpers/url';

const Carousel = ({
  isFullRedirect = true,
  categoryId = 'all'
}: {
  isFullRedirect?: boolean;
  categoryId?: string;
}) => {
  const { t, lang } = useTranslation();
  const [banners, setBanners] = useState<BannerType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isBannerError, setIsBannerError] = useState<boolean>(false);
  const router = useRouter();
  const { license } = useExtraProps();
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    const fetcher = async (url: string) => {
      setIsLoading(true);
      const data = await fetch(url);
      const result = await data.json();
      setBanners(result);
      setIsLoading(false);
    };
    try {
      fetcher(
        `/api/cms/banner?locale=${getFallbackLanguage(
          lang
        )}&licences=${license}&positions=top&random=false&categoryId=${categoryId}&limit=5`
      );
    } catch (error) {
      setIsLoading(false);
      setIsBannerError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, categoryId]);

  useEffect(() => {
    // everytime a filter is applied, the filteredGames are updated, then the carousel should reset
    setSelected(0);
  }, [banners]);

  const getBorderRadius = (index: number): string => {
    const last = banners.length - 1;
    if (index === 0) {
      return '0 40px 0 0';
    } else if (index === last && banners.length < 5) {
      return '0 0 0 0';
    } else if (index === last) {
      return '0 0 40px 0';
    } else {
      return '0';
    }
  };

  const selectedBanner: BannerType = banners?.[selected];
  const hasUrl = Boolean(selectedBanner?.attributes?.redirectUrl?.trim());
  const showCTA =
    hasUrl &&
    isValidUrl(selectedBanner?.attributes?.redirectUrl?.trim()) &&
    Boolean(selectedBanner?.attributes?.cta?.trim());
  const bannerUrl: string =
    selectedBanner?.attributes?.picture?.data?.attributes?.url || '';
  const pictureSrc = bannerUrl.startsWith('http')
    ? bannerUrl
    : HTTP.defaults.baseURL + '/cms' + bannerUrl;

  const handleRedirect = (url?: string | null) => {
    void routeToUrl({ router, url });
  };
  // console.log('imgMetadata: ', {
  //   pictureSrc,
  //   bannerUrl,
  //   baseUrl: HTTP.defaults.baseURL
  // });

  const hasBanners = banners?.length > 0 && !isLoading && !isBannerError;
  const noDataBanner = () => (
    <NoGameFallback
      title={t('catalog:bannerNotFoundTitle')}
      subTitle={t('catalog:bannerNotFoundSubTitle')}
    />
  );

  return (
    <VStack
      width="100%"
      alignItems="baseline"
      id="trendy"
      paddingTop={hasBanners ? '3.565rem' : '1.565rem'} // Figma is the source of truth, Epic our saviour
    >
      {/* <HStack width="100%" mb="1rem">
        <FireIcon width={30} height={30} />
        <Text as="h2" textTransform="uppercase" fontWeight="bold" fontSize="xl">
          {t('catalog:trendy')}
        </Text>
      </HStack> */}
      {isLoading ? (
        <HStack justifyContent="center" alignItems="center" width="100%">
          <CircularProgress
            size="3em"
            isIndeterminate
            color="buttonPrimary"
            title={t('common:loadingGeneric')}
          />
        </HStack>
      ) : hasBanners ? (
        <>
          <MotionHStack
            align="baseline"
            alignItems="inherit"
            spacing="1.8rem"
            css={ScrollWithoutScrollBar}
            display={['flex', 'none']}
            initial={'initial'}
            transition={{
              staggerChildren: 0.15,
              delayChildren: 0.5
            }}
            whileInView={'animate'}
            viewport={{ once: true, amount: 0.7 }}
            pr={['1rem', 0]}
          >
            {banners?.map((banner, index) => (
              <BannerTitleAndImage
                banner={banner}
                key={index}
                data-testid={`banner-mobile-${index}`}
                borderRadius="1.8rem"
                flexGrow={0}
                flexShrink={0}
              />
            ))}
          </MotionHStack>

          <HStack
            overflow="hidden"
            // maxWidth={['700px', '880px', '992px', '1204px', '1405px', '1594px']}
            width="100%"
            height={['330px', '448px', '517px']} // '568px', '663px', '752px'
            borderRadius="40px"
            spacing="0"
            align="stretch"
            display={['none', 'flex']}
          >
            <HStack flex={'0 0 75%'} alignItems={`inherit`}>
              <GameImage
                src={pictureSrc}
                data-testid={`banner`}
                // borderRadius="0"
                // width="100%"
                // height={'100%'}
                width={'auto'}
                borderRadius={'1.875rem 0 0 1.875rem'}
                onClick={() =>
                  handleRedirect(selectedBanner?.attributes?.redirectUrl)
                }
                cursor={isFullRedirect && hasUrl ? 'pointer' : 'default'}
                stackProps={{ width: '100%' }}
              >
                <MotionVStack
                  align="baseline"
                  top={['10%', '10%', '25%', '35%']}
                  left="2.5rem"
                  position="absolute"
                  width="100%"
                  spacing="0"
                  initial={'initial'}
                  transition={{
                    staggerChildren: 0.15,
                    delayChildren: 0.5
                  }}
                  whileInView={'animate'}
                  viewport={{ once: true, amount: 0.7 }}
                  cursor={isFullRedirect && hasUrl ? 'pointer' : 'default'}
                >
                  <GameTitle
                    variants={bottomUPChildrenElementAnimation}
                    fontSize="2rem"
                    dangerouslySetInnerHTML={{
                      __html: selectedBanner?.attributes.title.replace(
                        /\n/g,
                        '<br />'
                      )
                    }}
                  ></GameTitle>
                  <GameSubTitle
                    variants={bottomUPChildrenElementAnimation}
                    marginBottom="2rem !important"
                    dangerouslySetInnerHTML={{
                      __html: selectedBanner?.attributes.description
                    }}
                  ></GameSubTitle>
                  {showCTA && (
                    <Button
                      backgroundColor="buttonPrimary"
                      as="a"
                      href={selectedBanner?.attributes?.redirectUrl}
                      target={selectedBanner?.attributes?.ctaTarget || '_blank'}
                      padding={`${toRem(7.5)} ${toRem(27)}`}
                    >
                      {selectedBanner?.attributes.cta}
                    </Button>
                  )}
                </MotionVStack>
              </GameImage>
            </HStack>
            <MotionVStack
              overflow="hidden"
              // backgroundColor="whiteAlpha.100"
              align="baseline"
              justifyContent="start"
              height="100%"
              flex="0 0 25%"
              spacing="0"
              initial={'initial'}
              transition={{
                staggerChildren: 0.15,
                delayChildren: 0.5,
                delay: 0.5
              }}
              whileInView={'animate'}
              viewport={{ once: true, amount: 0 }}
              key={banners?.length}
              backgroundColor="backgroundPrimaryDarker"
            >
              {banners?.map((banner, index) => (
                <MotionHStack
                  position="relative"
                  borderRadius={getBorderRadius(index)}
                  key={`Banner${banner.id}`}
                  data-testid={`banner-right-${index}`}
                  onClick={() => setSelected(index)}
                  width="100%"
                  height="20%"
                  display={'flex'}
                  variants={leftToRightChildrenElementAnimation}
                  backgroundColor={
                    selected === index
                      ? 'backgroundPrimary.500'
                      : 'backgroundPrimaryDarker'
                  }
                  _hover={{ backgroundColor: 'backgroundPrimary.500' }}
                  alignItems={'center'}
                  spacing="2rem"
                  padding={'1.5rem 2rem'}
                  className={
                    selected === index && banners.length > 1
                      ? 'progress-bar'
                      : ''
                  }
                  onAnimationEnd={() => {
                    if (selected >= banners?.length - 1) {
                      setSelected(0);
                    } else {
                      setSelected(selected + 1);
                    }
                  }}
                  _after={{
                    left: 0,
                    backgroundColor: 'bar',
                    animationDuration: banner.attributes.delay
                      ? `${banner.attributes.delay}ms`
                      : '6000ms'
                  }}
                >
                  <VStack
                    align="baseline"
                    spacing="0"
                    flex="1"
                    position={'relative'}
                  >
                    <BannerTitle
                      label={banner.attributes.title.replace(/\n/g, '<br />')}
                    ></BannerTitle>
                  </VStack>
                </MotionHStack>
              ))}
            </MotionVStack>
          </HStack>
        </>
      ) : null}
    </VStack>
  );
};

type BannerTitleProps = {
  children?: ReactElement | null;
  label: string;
  props?: TextProps;
};
const BannerTitle = ({ children, label, ...props }: BannerTitleProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflown, setIsOverflown] = useState(false);
  useEffect(() => {
    const element = ref.current;
    if (element) {
      setIsOverflown(element.scrollHeight > element.clientHeight);
    }
  }, []);
  return (
    <Tooltip
      label={
        <MotionText
          dangerouslySetInnerHTML={{
            __html: label
          }}
        ></MotionText>
      }
      placement={'right'}
      hasArrow
      isDisabled={!isOverflown}
    >
      <MotionText
        as="h3"
        ref={ref}
        cursor={'pointer'}
        noOfLines={2}
        dangerouslySetInnerHTML={{
          __html: label
        }}
        fontWeight="500"
        fontSize={['sm', 'sm', 'md', 'lg', 'xl']}
        color="#fff"
        {...props}
      ></MotionText>
    </Tooltip>
  );
};
export default Carousel;
