import { useExtraProps } from '@/pages/_app';
import { getFullUrlByLicense } from '@/utils/multiDomains';
import { Button, ButtonProps } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';

const RegisterButton = ({ ...props }: ButtonProps) => {
  const { t, lang: locale } = useTranslation();
  const { license } = useExtraProps();
  return (
    <Button
      data-testid="register-button"
      as="a"
      href={getFullUrlByLicense(license, locale) + '/register'}
      alignSelf="flex-end"
      spacing="0"
      align="baseline"
      color="white"
      backgroundColor="buttonPrimary"
      paddingY="1rem !important"
      {...props}
    >
      {t('common:register')}
    </Button>
  );
};

export default RegisterButton;
