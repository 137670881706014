import { Locale } from '@/components/GamesCatalog/types';
import { useAuth } from '@/context/Auth';
import { useCurrentHost } from '@/hooks/useCurrentHost';
import useLocalStorage from '@/hooks/useLocalStorage';
import { getLicenseByHost } from '@/utils/multiDomains';
import { ToastId } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { HTTP } from '../components';

const apiUrl = HTTP?.defaults?.baseURL || '';

export type NotificationContextType = {
  userNotifications: UserNotification[];
  unreadUserNotifications: string[];
  notificationOverviewOpened: boolean;
  setNotificationOverviewOpened: (opened: boolean) => void;
  generalNotifications: Notification[];
  notifToShow: Notification[];
  closeNotification: (id: ToastId) => void;
  loadUserNotifications: () => void;
};

export type NotificationTranslation = {
  locale: Locale;
  content: string;
  cta: string;
  redirectUrl: string;
};

export type Notification = {
  id: string;
  name: string;
  translations: NotificationTranslation[];
  createdAt: string;
  availableFrom: string;
  availableTo: string;
  status: 'draft' | 'pending' | 'published' | 'deleted' | 'expired';
  createdBy: {
    userId: string;
    username: string;
    fullName: string;
    type: 'admin' | string;
  };
  updatedAt: string;
  closed?: false;
};

export type UserNotification = {
  id: string;
  campaign: string;
  clickedAt: Date;
  content: string;
  redirectUrl: string;
  redirectTarget: '_blank' | '_self';
  template?: {
    translations?: [
      {
        redirectUrl: string;
        locale: string;
        content: string;
      }
    ];
  };
  seenAt: Date;
  sentAt: Date;
  userId: string;
};

type EventResponse = {
  type: EventMessageType;
  data: Notification | UserNotification;
};

type EventMessageType =
  | 'general_notifications'
  | 'update_notification'
  | 'delete_notification'
  | 'user_notification'
  | 'campaign_deleted';

export const NotificationContext = createContext<NotificationContextType>({
  userNotifications: [],
  unreadUserNotifications: [],
  notificationOverviewOpened: false,
  setNotificationOverviewOpened: () => {},
  generalNotifications: [],
  notifToShow: [],
  closeNotification: () => {},
  loadUserNotifications: () => {}
});

export const useNotification = () => useContext(NotificationContext);

export const getNotifications = async () => {
  try {
    return await (
      await HTTP.get('/notification/published_general_notifications')
    ).data;
  } catch {
    throw new Error('Could not get notifications');
  }
};

export const NotificationProvider = ({ children }: PropsWithChildren) => {
  const [closedNotifications, setClosedNotifications] = useLocalStorage<
    ToastId[]
  >('closedNotifications', []);

  const [generalNotifications, setGeneralNotifications] = useState<
    Notification[]
  >([]);

  const closeNotification = (id: ToastId) => {
    // remove the notification from the state
    setGeneralNotifications((prevState) =>
      prevState.filter((n) => n.id !== id)
    );

    // Add the notification to the closed notifications
    setClosedNotifications((prevState) => [...prevState, id]);
  };

  const openNotification = (id: ToastId) => {
    // Remove the notification from the closed notifications
    setClosedNotifications((prevState) => prevState.filter((n) => n !== id));
  };

  const isClosed = (id: ToastId) => {
    return closedNotifications.includes(id);
  };

  const updateNotification = (
    generalNotifications: Notification[],
    notification: Notification
  ) => {
    return generalNotifications.map((n) => {
      if (n.id === notification.id) {
        return notification;
      }
      return n;
    });
  };

  const handleGeneralNotificationMessage = (notification: Notification) => {
    if (
      notification.status === 'deleted' ||
      notification.status === 'expired'
    ) {
      closeNotification(notification.id);
      return;
    }

    setGeneralNotifications((prevGeneralNotifications) => {
      const existingNotification = prevGeneralNotifications.find(
        (n) => n.id === notification.id
      );
      if (existingNotification) {
        if (notification.status === 'published') {
          openNotification(notification.id);
        }

        // update current notification
        return updateNotification(prevGeneralNotifications, notification);
      }

      // add new notification
      return [notification, ...prevGeneralNotifications];
    });
  };

  const addNotifications = (notifications: Notification[]) => {
    const updatedNotifications = notifications.map((notification) => {
      const existingNotification = generalNotifications.find(
        (n) => n.id == notification.id
      );
      if (
        notification.status === 'deleted' ||
        notification.status === 'expired'
      ) {
        closeNotification(notification.id);
      }
      if (existingNotification) {
        if (notification.status === 'published') {
          openNotification(notification.id);
        }
        notifications = updateNotification(notifications, notification);
        return {
          ...existingNotification,
          ...notification
        };
      }
      return notification;
    });
    setGeneralNotifications(updatedNotifications as Notification[]);
  };

  const notifToShow = generalNotifications
    .filter((notification) => {
      return (
        !isClosed(notification.id) &&
        (notification.availableTo
          ? new Date(notification.availableTo).getTime() >= new Date().getTime()
          : true)
      );
    })
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
    .slice(0, 2);

  // USER NOTIFICATIONS

  const { isLogged, isLoginFetching } = useAuth();
  const hostname = useCurrentHost();
  const license = getLicenseByHost(hostname);
  const { lang } = useTranslation();

  const [userNotifications, setUserNotifications] = useState<
    UserNotification[]
  >([]);

  const unreadUserNotifications: string[] = useMemo(() => {
    return userNotifications
      .filter((notification) => !notification.seenAt)
      .map((notification) => notification.id);
  }, [userNotifications]);

  const [notificationOverviewOpened, setNotificationOverviewOpened] =
    useState(false);

  const loadUserNotifications = async () => {
    if (isLogged && !isLoginFetching) {
      const lastTwoWeeksDate = new Date();
      lastTwoWeeksDate.setDate(lastTwoWeeksDate.getDate() - 14);
      const { data } = await HTTP.get(
        `notification/notifications?order[sentAt]=desc&limit=20&sentAt[after]=${lastTwoWeeksDate.toISOString()}&campaign.licenses=${license}%2B`,
        {
          headers: {
            'Accept-Language': lang
          }
        }
      );
      setUserNotifications(data || []);
    }
  };

  const addUserNotification = (notification: UserNotification) => {
    const translatedNotification = notification.template?.translations?.find(
      (notificationTranslated) => notificationTranslated.locale === lang
    );
    if (translatedNotification) {
      notification.content = translatedNotification?.content;
      notification.redirectUrl = translatedNotification?.redirectUrl;
    }
    setUserNotifications((prevState) => [notification, ...prevState]);
  };

  const removeCampaignNotifications = (campaignDeleted: any) => {
    setUserNotifications((prevUserNotifications) =>
      prevUserNotifications.filter((notification) => {
        return notification.campaign !== `/campaigns/${campaignDeleted.id}`;
      })
    );
  };

  useEffect(() => {
    loadUserNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, isLogged, isLoginFetching]);

  useEffect(() => {
    if (isLoginFetching) return;

    getNotifications()
      .then((notifications) => {
        addNotifications(notifications);
      })
      .catch((e) => console.error(e));

    const sse = new EventSource(`${apiUrl}/events/stream`, {
      withCredentials: true
    });

    if (sse) {
      sse.onopen = (event) => {
        console.log(
          `SSE connection opened (${isLogged ? 'online' : 'offline'} user)`
        );
      };

      sse.onmessage = (event) => {
        const data: EventResponse = JSON.parse(event.data);
        switch (data.type) {
          case 'general_notifications':
            handleGeneralNotificationMessage(data.data as Notification);
            break;
          case 'user_notification':
            addUserNotification(data.data as UserNotification);
            break;
          case 'campaign_deleted':
            removeCampaignNotifications(data.data);
            break;
          default:
            break;
        }
      };
      sse.onerror = (err) => {
        console.error(err);
      };
    }

    return () => {
      console.log('Closing SSE connection');
      sse?.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, isLoginFetching]);

  return (
    <NotificationContext.Provider
      value={{
        userNotifications,
        unreadUserNotifications,
        notificationOverviewOpened,
        setNotificationOverviewOpened,
        generalNotifications,
        notifToShow,
        closeNotification,
        loadUserNotifications
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
