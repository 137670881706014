import { useEffect, useState } from 'react';
import { RefObject } from 'react';

type ResizeObserverHookResult = {
  width: number;
  height: number;
};

function useResizeObserver(
  target: HTMLElement | RefObject<HTMLElement> = document.body
): ResizeObserverHookResult {
  const [dimensions, setDimensions] = useState<ResizeObserverHookResult>({
    width: target instanceof HTMLElement ? target.clientWidth : 0,
    height: target instanceof HTMLElement ? target.clientHeight : 0
  });

  useEffect(() => {
    let element: HTMLElement | null = null;

    if (target instanceof HTMLElement) {
      element = target;
    } else if (target.current instanceof HTMLElement) {
      element = target.current;
    }

    if (!element) return;

    const resizeObserverCallback: ResizeObserverCallback = (entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        setDimensions({ width, height });
      }
    };

    const resizeObserver = new ResizeObserver(resizeObserverCallback);
    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  }, [target]);

  return dimensions;
}

export default useResizeObserver;
