import React, { FC, useEffect, useRef, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { PortalProps } from './Portal.types';

const Portal: FC<PortalProps> = ({ children, container = '__next' }) => {
  const currentElement = useRef(document.createElement('Fragment'));
  currentElement.current.setAttribute('role', 'fragment');

  useEffect((): any => {
    const rootPortal: HTMLElement | null = document.getElementById(container);
    const element = currentElement.current;

    rootPortal?.appendChild(element);

    return () => rootPortal?.removeChild(element);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ReactDOM.createPortal(children, currentElement?.current);
};

export default Portal;
