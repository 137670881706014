import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  GenericObject,
  Provider,
  Translation
} from '@/components/GamesCatalog/types';
import { InitialFieldsType } from '@/context/Filters';

interface DataStore {
  [key: string]: any;
}

const findParentObjectByProperty = (
  array: GenericObject[] | (Provider & { translations?: never })[],
  property: keyof Translation[0],
  value: string
) => {
  for (const parentObject of array) {
    if (parentObject.translations) {
      for (const translation of parentObject.translations) {
        if (translation[property] === value) {
          return parentObject;
        }
      }
    } else {
      if ((parentObject as Provider)[property as keyof Provider] === value) {
        return parentObject;
      }
    }
  }
  return {};
};

const useQueryParamInitializer = (
  InitialFields: InitialFieldsType,
  dataStore: DataStore | undefined,
  setFilterFields: (
    value: InitialFieldsType | ((val: InitialFieldsType) => InitialFieldsType)
  ) => void
) => {
  const router = useRouter();
  const { segment: s, slug: sg, ...rest } = router.query;
  const queryParam: any = rest;
  const initObj: { [key in string]: any } = {};
  const [filters, setFilters] = useState<InitialFieldsType>();

  const removeKeysNotInArray = <T extends object, K extends keyof T>(
    obj: T,
    keysToKeep: K[]
  ): Pick<T, K> => {
    const newObj = {} as Pick<T, K>;
    keysToKeep.forEach((key) => {
      if (obj.hasOwnProperty(key)) {
        newObj[key] = obj[key];
      }
    });
    return newObj;
  };

  const queryParamCleaned = removeKeysNotInArray(
    queryParam,
    Object.keys(InitialFields || {})
  );

  const init = () => {
    const updatedInitObj: { [key in string]: any } = { ...initObj };

    for (const key in queryParamCleaned) {
      const currentQueryParamValues = rest[key];
      const currentQueryParamValuesArray = Array.isArray(
        currentQueryParamValues
      )
        ? currentQueryParamValues
        : [currentQueryParamValues];

      const currentEntity = Object.values(
        dataStore?.[key as keyof DataStore] ?? {}
      );

      updatedInitObj[key] = [];

      for (const entity of currentQueryParamValuesArray) {
        if (typeof entity === 'string') {
          if (key !== 'volatility') {
            const foundBySlug = findParentObjectByProperty(
              currentEntity as any[],
              'slug',
              entity
            ) as any;

            updatedInitObj[key].push(foundBySlug?.['@id']);
          } else {
            updatedInitObj[key].push(+entity);
          }
        }
      }
    }

    // store filter with the new values provided by the queryparam
    setFilterFields((prevState) => {
      setFilters({
        ...prevState,
        ...updatedInitObj
      });
      return {
        ...prevState,
        ...updatedInitObj
      };
    });
  };

  useEffect(() => {
    if (Object.keys(queryParam || {}).length) {
      init();
    }
  }, [router.query]);

  return { updatedFilters: filters };
};

export default useQueryParamInitializer;
