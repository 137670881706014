import { useAppSettings } from '@/context/AppSettings';
import { SupportIcon } from '@/theme/Icons';
import {
  Button,
  ButtonProps,
  Icon,
  IconProps,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  StackProps,
  Text,
  TextProps,
  useDisclosure
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';

type SupportButtonModalProps = StackProps &
  ButtonProps & {
    iconProps?: IconProps;
    textProps?: TextProps;
  };

const SupportButtonModal = ({
  iconProps,
  textProps,
  ...props
}: SupportButtonModalProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack
        alignItems="center"
        justifyContent="center"
        as={Button}
        onClick={() => {
          window.FreshworksWidget?.('open');
        }}
        data-testid="support-button"
        {...props}
      >
        <Icon
          as={SupportIcon}
          color="white"
          marginRight="0.5rem"
          {...iconProps}
        />
        <Text
          whiteSpace="nowrap"
          fontSize="1rem"
          margin="0 !important"
          {...textProps}
        >
          {t('common:serviceSupport')}
        </Text>
      </Stack>
    </>
  );
};

export default SupportButtonModal;
