import WalletButton from '@/components/Button/WalletButton';
import { useFormatCurrency } from '@/hooks/useFormat';
import { AccountIcon } from '@/theme/Icons';
import { HStack, StackProps, Text, VStack } from '@chakra-ui/react';
import Link from 'next/link';
import { useEffect } from 'react';
import { useAuth } from '../../../context/Auth';
import { useWallet } from '../../../context/Wallet';

type ProfilTinyInfoProps = StackProps & {};

const ProfileTinyInfo = ({ ...props }: ProfilTinyInfoProps) => {
  const { isLogged, userData } = useAuth();
  const { getBalance, balance } = useWallet();

  const balanceAmount = useFormatCurrency({ amount: balance?.amount });

  useEffect(() => {
    if (isLogged) {
      getBalance?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  return (
    <>
      {isLogged && !!Object.keys(userData || {}).length && (
        <VStack align="baseline" spacing="1.5rem" {...props}>
          <Link href="/me/account/my-profile" passHref>
            <HStack
              minWidth="12rem"
              padding="0.3rem"
              borderRadius="1rem"
              border="1px solid rgba(142, 148, 179, 0.4)"
              as="a"
            >
              <AccountIcon width="3.7rem" height="3.7rem" />
              <Text
                fontWeight="bold"
                fontSize="1.2rem"
                maxWidth="10rem"
                noOfLines={1}
              >
                {userData?.username}
              </Text>
            </HStack>
          </Link>
          <HStack>
            <WalletButton />
            <Text data-testid="profile-balance-amount">{balanceAmount}</Text>
          </HStack>
        </VStack>
      )}
    </>
  );
};

export default ProfileTinyInfo;
