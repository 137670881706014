import { HStack, StackProps } from '@chakra-ui/react';
import { useAuth } from '../../context/Auth';
import { SignInAndRegisterButtons } from '../Button/SignInButton';
import LogoTestAccount from '../Logo/LogoTestAccount';
import { CatalogTinyInfo } from '../Profile/Profile_TinyInfo/CatalogTinyInfo';

type NavbarProps = StackProps & {};

const InGameNavBar = ({ ...props }: NavbarProps) => {
  const { isLogged } = useAuth();

  return (
    <HStack
      justifyContent="space-between"
      zIndex="sticky"
      className="blurry"
      as="header"
      width="100%"
      paddingY={['0.5rem', '0.5rem', '1rem']}
      paddingX={['1rem', '1rem', '2rem']}
      {...props}
    >
      <LogoTestAccount small />
      {/* <Search /> */}
      {isLogged ? <CatalogTinyInfo isPlaying /> : <SignInAndRegisterButtons />}
    </HStack>
  );
};

export default InGameNavBar;
