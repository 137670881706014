import { useAppSettings } from '@/context/AppSettings';
import { useAuth } from '@/context/Auth';
import {
  DomainLicense,
  getFullUrlByLicense,
  getLicenseByPath,
  LicensesEnabled
} from '@/utils/multiDomains';
import {
  Box,
  Button,
  ButtonProps,
  Divider,
  Grid,
  GridItem,
  GridProps,
  HStack,
  Image,
  Img,
  Link as LinkChakra,
  Select,
  SelectProps,
  Stack,
  Text,
  Tooltip,
  VStack
} from '@chakra-ui/react';
import setLanguage from 'next-translate/setLanguage';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { HTTP } from '../Http';
import LogoTestAccount from '../Logo/LogoTestAccount';
import SupportButtonModal from '../Modal/SupportButtonModal';
import { PaymentMethod } from '../UserSettings/Wallet/CashIn/CashInForm';
import SocialLinks from '@/components/SocialLinks';
import { getFallbackLanguage } from '@/helpers/lang';

type PaymentCardProps = {
  icon: any;
  label: string;
  gridProps?: GridProps;
};

const PaymentCard = ({ icon, label, gridProps }: PaymentCardProps) => (
  <Grid
    padding="0.1rem"
    width="3rem"
    height="2rem"
    borderRadius="lg"
    backgroundColor="white"
    placeItems="center"
    overflow="hidden"
    alignItems="center"
    justifyContent="center"
    position="relative"
    style={{
      marginInlineStart: '0rem',
      marginInlineEnd: '0rem',
      marginRight: '.5rem',
      marginBottom: '.5rem'
    }}
    {...gridProps}
  >
    <Tooltip label={label}>
      {/* <Icon src width="100%" height="100%" /> */}
      <Image
        src={icon}
        alt={label}
        position="absolute"
        maxWidth="90%"
        maxHeight="90%"
      />
    </Tooltip>
  </Grid>
);

export const PaymentMethodsIcons: FC<{ gridProps?: GridProps }> = ({
  gridProps
}) => {
  const [paymentsData, setPaymentData] = useState<PaymentMethod[]>([]);

  const getAvailablePayments = async () => {
    const data = await fetch('/api/paymentMethods');
    const paymentsMethod = await data.json();
    setPaymentData(paymentsMethod);
  };

  useEffect(() => {
    getAvailablePayments();
  }, []);

  return (
    <>
      {paymentsData.map((card, index) => (
        <PaymentCard
          key={index}
          icon={card.icon}
          label={card.method}
          gridProps={gridProps}
        />
      ))}
    </>
  );
};

const changeLanguage = async (e: React.ChangeEvent<HTMLSelectElement>) => {
  HTTP.defaults.headers.common['Accept-Language'] = getFallbackLanguage(
    e.target.value as string
  );

  // setLocale() doesn't work, so we destroy and reinitialize inside component
  // window.fcWidget?.user.setLocale(e.target.value);
  window.fcWidget?.destroy();

  await setLanguage(e.target.value);
  document.cookie = `NEXT_LOCALE=${e.target.value}; max-age=31536000; path=/`;
};

export const LanguageSelector: FC<{
  lang: string;
  selectProps?: SelectProps;
}> = ({ lang, selectProps }) => {
  const config = useAppSettings();
  const availableLanguagesObject: Record<string, string> =
    config.availableLanguagesObject;
  return (
    <Select
      data-testid="change-language-select"
      flex="1"
      onChange={changeLanguage}
      border="none"
      value={lang}
      fontWeight="bold"
      bg="backgroundPrimaryDarker"
      {...selectProps}
    >
      {Object.entries(availableLanguagesObject).map(([locale, label]) => {
        return (
          <option key={`locale-${locale}`} value={locale}>
            {label}
          </option>
        );
      })}
    </Select>
  );
};

export const FooterInternalLinks: FC<{
  baseUrl?: string;
  buttonProps?: ButtonProps;
}> = ({ baseUrl, buttonProps }) => {
  const { isLogged, userData } = useAuth();
  const isSelfExcluded = userData?.status === 'self_excluded';
  const { lang, t } = useTranslation();
  const baseUrlWithLang = baseUrl || getFullUrlByLicense('MAIN', lang);

  const links: { url: string; label: string; testId: string }[] = [
    {
      url: `${baseUrlWithLang}terms-of-use`,
      label: t('footer:termsOfUse'),
      testId: 'terms-of-use-button'
    },
    {
      url: `${baseUrlWithLang}privacy`,
      label: t('footer:privacyPolicy'),
      testId: 'privacy-button'
    },
    {
      url: `${baseUrlWithLang}cookies-policy`,
      label: t('footer:cookiesPolicy'),
      testId: 'cookies-policy-button'
    },
    {
      url:
        isLogged && !isSelfExcluded
          ? `/me/account/responsible-gaming`
          : `${baseUrlWithLang}responsible-gaming`,
      label: t('footer:responsibleGaming'),
      testId: 'responsible-gaming-button'
    }
  ];

  return (
    <>
      {links.map((link) => (
        <Link key={link.label} href={link.url} passHref>
          <Button
            data-testid={link.testId}
            as="a"
            fontSize="0.8rem"
            textTransform="uppercase"
            variant="link"
            {...buttonProps}
          >
            {link.label}
          </Button>
        </Link>
      ))}
    </>
  );
};

const Footer = () => {
  const { lang, t } = useTranslation();
  const router = useRouter();
  const margin = router.pathname.includes('/me') ? '0 auto' : '0 0 auto 0';
  const config = useAppSettings();

  const ageLicenseList: string[] = [];
  const license = getLicenseByPath(router.asPath);

  if (license === 'MAIN') {
    ageLicenseList.push(
      ...LicensesEnabled().filter((license) => license !== 'MAIN')
    );
  } else {
    ageLicenseList.push(license.toString());
  }

  const gamingLicenseEnabled = LicensesEnabled().filter(
    (license) => license !== 'MAIN'
  );

  const GamingLicenseCopy = () => {
    return (
      <Text as="span">
        {gamingLicenseEnabled.map((license) => {
          return (
            <Text key={license} as="span" display="block">
              Gaming License {license}+
            </Text>
          );
        })}
      </Text>
    );
  };

  const gamingLicenseText: {
    [key in keyof DomainLicense]: string | JSX.Element;
  } = {
    A: 'Gaming License A+',
    B: 'Gaming License B+',
    F: 'Gaming License F+',
    MAIN: <GamingLicenseCopy />
  };

  const partnersItems: Record<string, any> = {
    gamingCommission: {
      href: t('footer:websiteGamingCommission'),
      imageName: 'gamingcommission',
      text: gamingLicenseText[license]
    },
    bago: {
      href: t('footer:websiteBago'),
      imageName: 'bago',
      text: 'Founding member of'
    }
  };

  const partnersLinks: {
    href: string;
    imageName: string;
    text: string | JSX.Element;
  }[] = config?.partnersItems.map((key: string) => partnersItems[key]);

  return (
    <VStack
      data-js-layout-part={'footer'}
      as="footer"
      position="relative"
      zIndex="base"
      margin={margin}
      width="100%"
      align="baseline"
      background={
        config.customStyle.footerBackground
          ? config.customStyle.footerBackground
          : 'transparent'
      }
      backgroundSize="cover"
      backgroundBlendMode="hard-light"
      paddingTop="4rem"
    >
      <Divider
        width="90%"
        maxWidth="1920px"
        opacity="0.3"
        background="customGrey.900"
        marginTop="0!"
        marginBottom="1rem !important"
        marginX="auto !important"
      />

      <Grid
        gridTemplateColumns={[
          'repeat(2, 1fr)',
          'repeat(2, 1fr)',
          'repeat(6, 1fr)'
        ]}
        padding={['2rem 5% 2.8rem 5%', '2rem 5% 2.8rem 5%', '4rem 3rem']}
        gridRowGap="3rem"
        marginX={['0', 'auto !important', 'auto !important']}
        width={['100%', '90%', '90%']}
        maxWidth="1920px"
        gap={6}
      >
        <GridItem flexDirection="column">
          <LogoTestAccount
            width="8.125rem"
            badgeProps={{
              right: '-1rem'
            }}
            zIndex="inherit"
          />
          <SocialLinks />
        </GridItem>

        <GridItem colSpan={2}>
          <VStack gridColumnStart="span 2" align="baseline">
            <Text fontSize="0.8rem" fontWeight="bold">
              100% {t('footer:belgian')}. 100% {t('footer:secure')}
            </Text>
            <Text fontSize="0.6rem" color="customGrey.900">
              {t('footer:paymentsDescription')}
            </Text>
            <HStack flexWrap={'wrap'}>
              <PaymentMethodsIcons />
            </HStack>
          </VStack>
        </GridItem>
        {partnersLinks?.map((partnerLink) => (
          <GridItem key={partnerLink.href}>
            <a
              href={partnerLink.href}
              data-testid={`${partnerLink.imageName}-link`}
              target="_blank"
              rel="noreferrer"
            >
              <VStack align="baseline">
                {partnerLink.text ? (
                  <Text
                    textTransform="uppercase"
                    fontSize="0.8rem"
                    fontWeight="bold"
                    color="#fff"
                  >
                    {partnerLink.text}
                  </Text>
                ) : null}
                <Img
                  src={`/partners/${partnerLink.imageName}.webp`}
                  alt={`Partenaire ${partnerLink.text}`}
                  loading="lazy"
                />
              </VStack>
            </a>
          </GridItem>
        ))}
        <GridItem>
          <VStack alignItems={['flex-start', 'flex-start', 'center']}>
            <HStack>
              <LinkChakra isExternal href={t('footer:websitePlaySafe')}>
                <Img
                  src={`/partners/playsafe-small.webp`}
                  maxWidth="4rem"
                  alt="Play safe"
                  loading="lazy"
                />
              </LinkChakra>
              {ageLicenseList.map((ageLicense, index) => {
                return (
                  <Box
                    key={index}
                    background="white"
                    borderRadius="50%"
                    display="inline-block"
                    color="black"
                    fontWeight={'700'}
                    fontSize="0.8rem"
                    padding="0.2rem"
                  >
                    {t(`footer:ageLicense${ageLicense}`)}
                  </Box>
                );
              })}
            </HStack>
            <Box
              fontSize="0.6rem"
              dangerouslySetInnerHTML={{
                __html: t('footer:dependancePrevention')
              }}
            ></Box>
          </VStack>
        </GridItem>
      </Grid>
      {config.features?.clubPartners?.[lang] && (
        <Grid
          gridTemplateColumns={'repeat(6, 1fr)'}
          padding={['0 5% 2.8rem 5%', '0 5% 2.8rem 5%', '0 3rem 4rem 3rem']}
          gridRowGap="3rem"
          marginX={['0', 'auto !important', 'auto !important']}
          width={['100%', '90%', '90%']}
          maxWidth="1920px"
          gap={6}
          justifyItems="center"
        >
          {config.features.clubPartners[lang].map((partner: any) => {
            return (
              <GridItem
                key={partner.label}
                alignItems="center"
                maxWidth="3rem"
                display="flex"
              >
                <a href={partner.url} target="_blank" rel="noreferrer">
                  <Img
                    src={partner.imagePath}
                    maxWidth="100%"
                    title={partner.label}
                    alt={partner.label}
                    loading="lazy"
                  />
                </a>
              </GridItem>
            );
          })}
        </Grid>
      )}
      {/* Oria's internal links and language select */}
      <Stack backgroundColor="backgroundPrimaryDarker" width="100%">
        <Box
          maxWidth="1920px"
          marginX={['0', '0', 'auto !important']}
          width={['100%', '100%', '90%']}
          padding={['2rem 2rem 6rem 2rem', '2rem 2rem 6rem 2rem', '0.5rem 0']}
          display="flex"
          flexWrap="wrap"
          textAlign="left"
        >
          <Button
            // gridColumnStart={['span 2', 'span 2', 'span 1']}
            fontSize="1rem"
            fontWeight="700"
            variant="link"
            width="auto"
            cursor="initial"
            flex={['100%', '100%', 'initial']}
            marginBottom={['0.5rem', '0.5rem', '0']}
            justifyContent="flex-start"
            _hover={{
              textDecoration: 'none'
            }}
          >
            <Text as="span">&#9400;&nbsp;</Text>
            {config.appName.charAt(0).toUpperCase() +
              config.appName.slice(1)}{' '}
            {new Date().getFullYear()}
          </Button>

          {config.aboutLink && config.aboutLink[lang] && (
            <LinkChakra
              href={config.aboutLink[lang]}
              isExternal
              fontSize="0.8rem"
              fontWeight="700"
              color="link !important"
              lineHeight="inherit !important"
              margin={[
                '1.25rem 0 0 0 !important',
                '1.25rem 0 0 0 !important',
                '0 0 0 1rem !important'
              ]}
              flex={['50%', '50%', 'initial']}
              justifyContent="flex-start"
              textTransform="uppercase"
              textAlign="left"
              border="none !important"
              alignSelf="center"
              _hover={{
                background: 'none !important',
                textDecoration: 'underline'
              }}
            >
              {t('footer:about')}
            </LinkChakra>
          )}

          <FooterInternalLinks
            buttonProps={{
              flex: ['50%', '50%', 'initial'],
              justifyContent: 'flex-start',
              fontSize: '0.8rem',
              fontWeight: '700',
              textTransform: 'uppercase',
              whiteSpace: 'normal',
              margin: [
                '1.25rem 0 0 0 !important',
                '1.25rem 0 0 0 !important',
                '0 0 0 1rem !important'
              ],
              color: 'link',
              textAlign: 'left',
              alignSelf: 'center',
              style: {
                wordWrap: 'break-word',
                hyphens: 'auto'
              }
            }}
          />

          <Button
            id="ot-sdk-btn"
            data-testid="cookies-button"
            as="a"
            variant="link"
            className="ot-sdk-show-settings"
            fontSize="0.8rem"
            fontWeight="700"
            color="link !important"
            lineHeight="inherit !important"
            margin={[
              '1.25rem 0 0 0 !important',
              '1.25rem 0 0 0 !important',
              '0 0 0 1rem !important'
            ]}
            flex={['50%', '50%', 'initial']}
            justifyContent="flex-start"
            textTransform="uppercase"
            textAlign="left"
            border="none !important"
            _hover={{
              background: 'none !important',
              textDecoration: 'underline'
            }}
          >
            Cookies
          </Button>

          <HStack
            margin={[
              '1.25rem 0 0 0 !important',
              '1.25rem 0 0 0 !important',
              '0 !important'
            ]}
            marginLeft={[0, 0, 'auto !important']}
            paddingLeft={[0, 0, '1rem']}
            flexWrap={['wrap', 'wrap', 'nowrap']}
            gap={['1rem']}
            display={['block', 'block', 'flex']}
          >
            <SupportButtonModal
              flexDirection="row"
              flex={['0 0 100%', '0 0 50%']}
              justifyContent="flex-start"
              textTransform="uppercase"
              variant="link"
              iconProps={{
                width: '1.5rem',
                height: '1.5rem'
              }}
              textProps={{
                fontSize: '0.8rem',
                fontWeight: '700'
              }}
            />

            <LanguageSelector
              lang={lang}
              selectProps={{
                marginLeft: '0!',
                marginTop: ['2!', '2!', '0!'],
                flex: ['0 0 75%', '0 0 50%'],
                maxWidth: '10rem',
                textAlign: 'left',
                border: 'none',
                fontWeight: 'bold',
                bg: 'backgroundPrimaryDarker'
              }}
            />
          </HStack>
        </Box>
      </Stack>
    </VStack>
  );
};

export default Footer;
