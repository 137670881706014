import { useAuth } from '@/context/Auth';
import useTranslation from 'next-translate/useTranslation';
import getConfig from 'next/config';
import Script from 'next/script';
import { useCallback, useEffect, useMemo, useState } from 'react';

const SmarticoScript = ({ extraOnload }: { extraOnload?: () => void }) => {
  const { userData } = useAuth();
  const { lang } = useTranslation();
  const { publicRuntimeConfig } = getConfig();

  const smarticoConfig = useMemo(
    () => publicRuntimeConfig?.currentAppConfig?.features?.smartico || {},
    [publicRuntimeConfig]
  );

  const onLoad = useCallback(() => {
    if (window && window._smartico) {
      window._smartico?.clear();
      window._smartico_user_id = userData?.id || null;
      window._smartico_language = userData ? lang : null;
      window._smartico?.init(smarticoConfig.label_key, {
        brand_key: smarticoConfig.brand_key
      });
      extraOnload?.();
    }
  }, [smarticoConfig, extraOnload, lang, userData]);

  // if navigation by history (navigator next/back) onLoad script is not called
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Script
      src={smarticoConfig.js}
      strategy={'lazyOnload'}
      onLoad={() => onLoad()}
    />
  );
};

export default SmarticoScript;

declare global {
  interface Window {
    _smartico?: {
      init(label_key: string, options: { brand_key: string }): void;
      miniGame(id: number, options?: any): void;
      dp(param: string): void;
      clear(): void;
    };
    _smartico_user_id: string | null;
    _smartico_language: string | null;
  }
}
