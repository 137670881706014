
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
/* eslint-disable @next/next/no-sync-scripts */
import { ChakraWrapper } from '@/components/Chakra/ChakraWrapper';
import { AppSettingsProvider, useAppSettings } from '@/context/AppSettings';
import { NotificationProvider } from '@/context/Notification';
import GTM from '@/helpers/googleTagManager';
import interceptor from '@/services/interceptor';
import {
  DomainLicense,
  getLicenseByHost,
  getLicenseByPath,
  getLicenseBySegment,
  getPathByLicense,
  getUrlInfoFromRouter,
  setConfigDomains
} from '@/utils/multiDomains';
import { Box, CircularProgress, HStack } from '@chakra-ui/react';
import I18nProvider from 'next-translate/I18nProvider';
import useTranslation from 'next-translate/useTranslation';
import type { AppContext, AppProps, AppType } from 'next/app';
import App from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import Script from 'next/script';
import { createContext, useContext, useEffect, useState } from 'react';
import { Footer } from '../components';
import { AuthProvider } from '@/context/Auth';
import { LoginModalProvider } from '@/context/LoginModal';
import { WalletProvider } from '@/context/Wallet';
import WithProtectedRoute from '../hoc/WithProtectedRoute';
import '../styles/main.scss';
import dynamic from 'next/dynamic';
import FreshdeskButton from '@/components/Button/FreshdeskButton';
import SmarticoSpinWheel from '@/components/Smartico/SmarticoSpinWheel';
import { SeoTags } from '@/components/SEO/SeoTags';

const { publicRuntimeConfig } = getConfig();
const appName = publicRuntimeConfig.currentAppConfig.appName;
const themeColor =
  publicRuntimeConfig?.currentAppConfig?.customStyle?.themeColor;
const routesWithoutFooter = [
  '/register',
  '/_altenar/forgot-password',
  '/_altenar/me/wallet/[tab]',
  '/_altenar/register',
  '/account-created'
];
const userbackToken = publicRuntimeConfig.currentAppConfig.userbackToken;

const onetrustIds = publicRuntimeConfig?.currentAppConfig?.onetrust || {};

setConfigDomains(publicRuntimeConfig.currentAppConfig.domains);

export type ExtraAppTypes = {
  license: keyof DomainLicense;
  licensePath: string;
};

export interface TProps extends AppProps {
  extra?: ExtraAppTypes;
}

export const ExtraPropsContext = createContext<ExtraAppTypes | undefined>(
  undefined
);

export function useExtraProps(): ExtraAppTypes {
  const extraProps = useContext(ExtraPropsContext);

  if (extraProps === undefined) {
    console.error(
      'useExtraProps must be used within a ExtraPropsContext.Provider'
    );
    return { license: 'MAIN', licensePath: '' };
  }

  return extraProps;
}

const NotificationList = dynamic(
  () => import('@/components/Notification/NotificationList')
);

const ScrollToTopButton = dynamic(
  () => import('@/components/Button/ScrollToTopButton')
);

const BackToReality = dynamic(() =>
  import('@/components/index').then((mod) => mod.BackToReality)
);

const MyApp: AppType = ({ Component, pageProps, router, extra }: TProps) => {
  // const router = useRouter();
  const [theme, setTheme] = useState<{}>();
  const { t, lang } = useTranslation();
  const pageWithoutFooter = routesWithoutFooter.includes(router.pathname);
  // @todo: remove altenar check when will no longer be used
  const isAltenarApp = router.asPath.includes('/_altenar');
  const config = useAppSettings();
  const freshDeskConfig = config?.features?.freshDesk;
  const { canonicalUrl, license, cleanedPathname, hostname } =
    getUrlInfoFromRouter(router);
  const onetrustIdByLicense =
    onetrustIds[extra?.license || 'MAIN'] || onetrustIds.MAIN;

  useEffect(() => {
    interceptor({
      router,
      t,
      extra
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadTheme = async () => {
      const currentTheme =
        extra?.license && extra.license !== 'MAIN'
          ? (await import(`theme/${appName}/${extra?.license}/index`)).default
          : (await import(`theme/${appName}/common.ts`)).default;

      setTheme(currentTheme);
    };
    void loadTheme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set min-height for main content
  useEffect(() => {
    if (!theme) return;

    const main = document.body.querySelector<HTMLDivElement>(
      '[data-js-layout-part="main"]'
    );
    const footer = document.body.querySelector<HTMLDivElement>(
      '[data-js-layout-part="footer"]'
    );

    if (main && footer) {
      const footerStyles = getComputedStyle(footer);
      main.style.minHeight = `calc(100vh - ${footerStyles.height} - ${footerStyles.marginTop})`;
    }
  }, [theme]);

  useEffect(() => {
    // Set the extra data in a cookie
    document.cookie = `extra=${extra}; path=/`;
  }, [extra]);

  const mainContentPaddingBottom = router.pathname.includes('/me')
    ? '0'
    : '8rem';

  return (
    <>
      <Head>
        <SeoTags
          canonicalUrl={canonicalUrl}
          config={(pageProps as any)?.seoTagsConfig}
        />

        <link rel="icon" type="image/x-icon" href={`/${appName}/favicon.ico`} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`/${appName}/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`/${appName}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`/${appName}/favicon-16x16.png`}
        />
        <link rel="manifest" href={`/${appName}/site.webmanifest`} />
        <link
          rel="mask-icon"
          href={`/${appName}/safari-pinned-tab.svg`}
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content={themeColor} />
        <meta name="theme-color" content={themeColor} />
        <link rel="canonical" href={canonicalUrl} />

        {router.locales?.map((locale) => {
          return (
            locale !== 'default' && (
              <link
                key={locale}
                rel="alternate"
                hrefLang={locale}
                href={`${hostname}/${locale}${cleanedPathname}`}
              />
            )
          );
        })}

        {/* <script
          src={`https://cdn.cookielaw.org/consent/${onetrustIdByLicense}/OtAutoBlock.js`}
        ></script> */}

        {/* Frame Busting - X-Frame Attack */}
        <script type="text/javascript">
          {`if (top.location.hostname != self.location.hostname) {
              top.location.href = self.location.href;
          }`}
        </script>
      </Head>
      <Script
        strategy={'lazyOnload'}
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-domain-script={onetrustIdByLicense}
        data-document-language="true"
      ></Script>

      <Script
        strategy={'lazyOnload'}
        id="inlineOneTrust"
      >{`function OptanonWrapper() {}`}</Script>
      {!!userbackToken && !isAltenarApp ? (
        <Script
          src="https://static.userback.io/widget/v1.js"
          strategy="afterInteractive"
          onLoad={() => {
            window?.Userback.init(userbackToken, {
              on_load: () => {
                console.log('Widget Load Successful');
              }
            });
          }}
        ></Script>
      ) : null}
      <AppSettingsProvider>
        <ChakraWrapper theme={theme}>
          <I18nProvider lang={router.locale}>
            <ExtraPropsContext.Provider value={extra}>
              <AuthProvider>
                <NotificationProvider>
                  <LoginModalProvider>
                    <WithProtectedRoute>
                      <WalletProvider>
                        <>
                          <BackToReality />
                          <NotificationList />
                          <Box width="100%" className="globalBox">
                            <ScrollToTopButton />
                            {freshDeskConfig?.enabled ? (
                              <FreshdeskButton />
                            ) : null}
                            <SmarticoSpinWheel />
                            {theme ? (
                              <Box
                                minHeight={'100vh'}
                                data-js-layout-part={'main'}
                                paddingBottom={mainContentPaddingBottom}
                                // fix for oria video on large screen
                                position={'relative'}
                                overflow={'hidden'}
                              >
                                <Component {...pageProps} extra={extra} />
                              </Box>
                            ) : (
                              <HStack
                                justifyContent="center"
                                alignItems="center"
                                width="100%"
                                height="100vh"
                                bg={themeColor}
                                opacity=".9"
                                backdropFilter="blur(8px)"
                              >
                                <CircularProgress
                                  size="3em"
                                  isIndeterminate
                                  color={themeColor}
                                  title={t('common:loadingGeneric')}
                                />
                              </HStack>
                            )}
                            {!pageWithoutFooter ? <Footer /> : null}
                          </Box>
                        </>
                      </WalletProvider>
                    </WithProtectedRoute>
                  </LoginModalProvider>
                </NotificationProvider>
              </AuthProvider>
            </ExtraPropsContext.Provider>
          </I18nProvider>
        </ChakraWrapper>
      </AppSettingsProvider>
    </>
  );
};
MyApp.getInitialProps = async (app: AppContext) => {
  const appProps = await App.getInitialProps(app);

  const {
    query: { segment },
    pathname
  } = app.ctx;
  const req = app.ctx.req;

  const isAltenar = pathname.startsWith('/_altenar');
  const host = req ? req.headers['host'] : window.location.host;

  const license =
    process.env.IS_BUILDING !== 'true' && pathname.startsWith('/me/')
      ? getLicenseByHost(host as string)
      : segment
      ? getLicenseBySegment(segment)
      : getLicenseByPath(pathname);
  // console.log({ segment, pathname });

  // const license = licenseByHost; //segment ? getLicenseBySegment(segment) : isMePage;
  // const license = getLicenseBySegment(segment);

  // @todo: remove altenar check when will no longer be used
  const extraProps = {
    license: isAltenar ? 'F' : license,
    licensePath: isAltenar ? '/sports' : getPathByLicense(license as string)
  };
  // console.log('license: ', license);
  // console.log('extraProps: ', extraProps);
  return { ...appProps, extra: extraProps };
};

const __Page_Next_Translate__ = MyApp;

export { getServerSideProps } from '@/components/Chakra/ChakraWrapper';

declare global {
  interface Window {
    Userback?: any;
  }
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  