import { useAuth } from '@/context/Auth';
import { useLoginModal } from '@/context/LoginModal';
import { getBrandComponent } from '@/helpers/getBrandComponent';
import useMediaQuery from '@/hooks/useMediaQuery';
import { Box, Button, ButtonProps, Tooltip } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import getConfig from 'next/config';
import SmarticoScript from '@/components/Smartico/SmarticoScript';

const SmarticoIconComponent = getBrandComponent('Smartico');

export const SmarticoButton = ({
  variant = 'outline',
  ...props
}: ButtonProps) => {
  const { isLogged } = useAuth();
  const { onOpen } = useLoginModal();
  const { t } = useTranslation();
  const { publicRuntimeConfig } = getConfig();
  const isScreenMobile = useMediaQuery('(max-width: 37.5rem)');

  const smarticoConfig =
    publicRuntimeConfig?.currentAppConfig?.features?.smartico || {};

  return smarticoConfig.enabled ? (
    <>
      <SmarticoScript />
      {!smarticoConfig.hidden ? (
        <Tooltip label={t('common:smarticoOpenMissions')}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              isLogged ? window._smartico?.dp('dp:gf') : onOpen();
            }}
            borderRadius="1rem"
            cursor="pointer"
            variant={variant}
            width="3.3rem"
            height="3.3rem"
            textAlign="center"
            position="relative"
            lineHeight="3.3rem"
            gap={variant === 'outline' ? 0 : 2}
            display="flex"
            flexDirection="column"
            fontWeight="semibold"
            fontSize="0.8rem"
            {...props}
          >
            <SmarticoIconComponent
              width={variant === 'outline' ? '30px' : undefined}
            />

            {isScreenMobile ? (
              <Box marginTop="0">{t('common:smarticoOpenMissions')}</Box>
            ) : null}
          </Button>
        </Tooltip>
      ) : null}
    </>
  ) : null;
};
