import { useAppSettings } from '@/context/AppSettings';
import { useAuth } from '@/context/Auth';
import { APIUserType } from '@/context/Auth.types';
import { toRem } from '@/helpers/toRem';
import { useCurrentHost } from '@/hooks/useCurrentHost';
import { useExtraProps } from '@/pages/_app';
import {
  AccountIcon,
  ENAideJoueur,
  FRAideJoueur,
  NLAideJoueur
} from '@/theme/Icons';
import { getFullUrlByDomain, getFullUrlByLicense } from '@/utils/multiDomains';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  UseDisclosureProps,
  UseModalProps
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Form, Input } from '../';
import useLogin from '../../hooks/useLogin';
import LogoTestAccount from '../Logo/LogoTestAccount';
import { strapiImageUrl } from '@/helpers/url';
import { useLoginModal } from '@/context/LoginModal';

export type LoginView = 'full' | 'modal';
type LoginModalProps = UseModalProps &
  UseDisclosureProps & {
    callback?: (user: APIUserType) => void;
    view: LoginView;
    onCloseModal?: () => void;
  };

const LoginModal = ({
  callback,
  view = 'modal',
  onCloseModal,
  ...props
}: LoginModalProps) => {
  const { lang, t } = useTranslation();
  const appSettings = useAppSettings();
  // const license = useGetLicense();
  const currentHost = useCurrentHost();
  const { isLogged } = useAuth();
  const router = useRouter();
  const { license } = useExtraProps();
  const { backgroundUrl } = useLoginModal();
  const background = backgroundUrl
    ? `url('${strapiImageUrl(backgroundUrl)}') no-repeat 50% 100%/cover`
    : appSettings.customStyle.loginSectionLeft[license];

  // some animation to simulate stars
  const Stars = () => {
    if (license === 'B' && !!appSettings.isStarsAnimationLoginEnabled) {
      return (
        <Box
          position="absolute"
          top="0"
          left={['0', '25%']}
          width={['100%', '74%']}
          height={['100%', '99%']}
          zIndex="-1"
          mixBlendMode="overlay"
        >
          {Array.from(Array(500)).map((_, idx) => (
            <div key={idx} className="stars"></div>
          ))}
        </Box>
      );
    }
    return null;
  };
  const icon = () => {
    switch (lang) {
      case 'en':
        return {
          logo: <ENAideJoueur width="6rem" height="3rem" />,
          url: 'https://gokhulp.be'
        };
      case 'nl':
        return {
          logo: <NLAideJoueur width="6rem" height="3rem" />,
          url: 'https://gokhulp.be'
        };
      default:
        return {
          logo: <FRAideJoueur width="6rem" height="3rem" />,
          url: 'https://joueurs.aide-en-ligne.be'
        };
    }
  };

  const CoreForm = () => {
    const { loading, onSubmit, form } = useLogin({
      callback,
      onEnd: props.onClose,
      license
    });
    return (
      <Form
        stackProps={{
          mt: '2rem',
          spacing: '1rem'
        }}
        onSubmit={onSubmit}
        {...form}
      >
        <Input
          id="loginModalUsername"
          type="text"
          fontSize="1.2rem"
          padding="1.5rem"
          label={t('common:userNameOrEmail')}
          name="username"
          minLength={3}
          validation={{
            required: 'Missing username'
          }}
        />
        <Input
          id="loginModalPassword"
          type="password"
          forceTextType={false}
          fontSize="1.2rem"
          padding="1.5rem"
          marginTop="1rem"
          label={t('common:enterYourPassword')}
          name="password"
          validation={{
            required: 'Missing password'
          }}
        />
        <Text color={'red.500'}></Text>
        <Button
          as="a"
          id="loginModalForgot"
          color="whiteAlpha.700"
          alignSelf="flex-start"
          variant="link"
          textDecoration="underline"
          marginBottom="2rem !important"
          fontSize="0.8rem"
          href={`${getFullUrlByLicense(license, lang)}/forgot-password`}
          className="forgotLink"
        >
          {t('common:forgotPassword')}
        </Button>
        <Button
          id="loginModalSubmit"
          isLoading={form.formState.isSubmitting || loading}
          backgroundColor="buttonPrimary"
          size="md"
          type="submit"
          paddingX="3rem"
          paddingY="1.8rem"
          fontSize="1.2rem"
          fontWeight="bold"
          marginBottom="1.25rem !important"
          width="100%"
        >
          {t('common:login')}
        </Button>
        <Text fontWeight="bold" fontSize="1rem" marginBottom="2rem !important">
          {t('common:notMemberYet')}
          {'  '}
          <Button
            as="a"
            id="loginModalRegister"
            color="buttonPrimary"
            variant="link"
            href={getFullUrlByLicense(license, lang) + '/register'}
          >
            {t('common:register')}
          </Button>
        </Text>
        <Divider />
        <IconButton
          id="loginModalHelp"
          aria-label="aide aux joueurs"
          color="white"
          size="lg"
          icon={icon().logo}
          as="a"
          variant="link"
          href={icon().url}
          target="_blank"
        />
      </Form>
    );
  };

  const FullView = () => (
    <Box
      display="grid"
      gridTemplateColumns={['0 auto', '25% 30% auto']}
      height={['100%', '100vh']}
      gridGap={['0', '8rem']}
      background="linear-gradient(180deg, #2B2B2B 0%, rgba(43, 43, 43, 0) 94.61%)"
      _after={{
        content: "''",
        background: appSettings.customStyle.mainBackground[license],
        width: '100%',
        height: '100%',
        top: 0,
        position: 'absolute',
        mixBlendMode: 'color',
        zIndex: -1
      }}
    >
      <Box
        className="login-left-section"
        background={background}
        position="relative"
      >
        <LogoTestAccount
          display={['none', 'block']}
          position="absolute"
          top="2rem"
          left="3rem"
          className="login-logo"
        />
      </Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        padding={['2rem', '0']}
        flexDirection="column"
        justifyContent={'center'}
        height="100%"
      >
        <Stars />
        <LogoTestAccount display={['block', 'none']} />
        <Box
          display={'flex'}
          alignItems="center"
          marginTop={'2rem'}
          flexDirection="column"
        >
          <AccountIcon width={toRem(46)} height={toRem(46)} />
          <Text fontWeight={'500'} fontSize={toRem(32)}>
            {t('common:signIn')}
          </Text>
        </Box>
        <CoreForm />
      </Box>
    </Box>
  );

  const ModalView = () => {
    return (
      <Box>
        <CoreForm />
      </Box>
    );
  };

  const mobileStyle =
    view === 'modal'
      ? {
          top: ['unset', 'unset', 'unset', '2rem'],
          right: ['unset', 'unset', 'unset', '2rem'],
          maxWidth: ['90%', '90%', '25rem'],
          paddingX: ['1rem', '1rem', '3rem'],
          paddingTop: '3rem',
          paddingBottom: '1rem',
          border: '1px solid',
          borderColor: 'whiteAlpha.300'
        }
      : {};

  useEffect(() => {
    // set var css for the animation stars
    document.body.style.setProperty(
      '--stars-animation-canvas-width',
      window?.innerWidth + 'px'
    );
    document.body.style.setProperty(
      '--stars-animation-canvas-height',
      window?.innerHeight + 'px'
    );
  }, []);

  return isLogged ? null : (
    <>
      <Modal isCentered {...props} size={view === 'modal' ? 'md' : 'full'}>
        <ModalOverlay className="modal-login" />
        <ModalContent position="absolute" height={'100%'} {...mobileStyle}>
          <ModalCloseButton
            top="1rem"
            right="1rem"
            onClick={() => {
              const url = getFullUrlByDomain(lang, currentHost);
              const isLoginPage = router.pathname.endsWith('login');
              if (isLoginPage && url) router.push(url);
              props.onClose();
              onCloseModal?.();
            }}
          />
          <>{view === 'modal' ? <ModalView /> : <FullView />}</>
        </ModalContent>
      </Modal>
    </>
  );
};
LoginModal.getInitialProps = async () => {
  return {};
};
export default LoginModal;
