import { useAuth } from '@/context/Auth';
import { CashOutRequest, useWallet } from '@/context/Wallet';
import { CurrencyBase } from '@/helpers/humanCurrency';
import { useFormatCurrency, useFormatDate } from '@/hooks/useFormat';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  StackProps,
  Text,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import { HTTP } from '../Http';
import ConfirmModal from '../Modal/ConfirmModal';
import SuccessModal from '../Modal/SuccessModal';

type CancelRequestedCashOutProps = StackProps & {};

const FormattedAmount = (amount: number) => {
  return useFormatCurrency({ amount, currencyBase: CurrencyBase.EURO });
};

const FormatDate = (date: string) => useFormatDate(date);

const CancelRequestedCashOut = ({ ...props }: CancelRequestedCashOutProps) => {
  const { getPendingCashOuts, pendingCashOuts } = useWallet();
  const toast = useToast();
  const { isLogged } = useAuth();
  const [currentRequest, setCurrentRequest] = useState<CashOutRequest | null>(
    null
  );
  const { t } = useTranslation();
  const showMoreModal = useDisclosure();
  const confirmModal = useDisclosure();
  const successModal = useDisclosure();
  const [loadingCancel, setLoadingCancel] = useState(false);

  useEffect(() => {
    getPendingCashOuts?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmCancel = async () => {
    if (!isLogged) return;
    setLoadingCancel(true);
    try {
      await HTTP.put(
        `/payout_requests/${currentRequest?.id}/cancel?confirm=true`,
        { note: 'from oria' }
      );
      successModal.onOpen();
      confirmModal.onClose();
      getPendingCashOuts?.();
      setLoadingCancel(false);
    } catch (error: any) {
      toast({
        status: 'error',
        position: 'top',
        title: t('common:somethingWrongHappenned')
      });
      setLoadingCancel(false);
      return;
    }
  };

  const CancelButton = ({ request }: { request: CashOutRequest }) => {
    return (
      <Button
        data-testid="cancel-request"
        variant="outline"
        display="flex"
        flexDirection="column"
        padding="3rem 2rem"
        onClick={() => {
          setCurrentRequest(request);
          confirmModal.onOpen();
        }}
      >
        <Text marginBottom="0.3rem" fontSize="0.8rem" color="whiteAlpha.600">
          {FormatDate(request.createdAt)}
        </Text>
        <Text marginBottom="0.3rem">{FormattedAmount(request.amount)}</Text>
        <Text fontWeight="bold" fontSize="1rem">
          {t('common:cancelMyRequest')}
        </Text>
      </Button>
    );
  };

  return (
    <VStack
      spacing="1rem"
      marginTop={pendingCashOuts.length ? '3rem' : 0}
      {...props}
    >
      {pendingCashOuts.length <= 0
        ? null
        : pendingCashOuts
            .slice(0, 2)
            .map((request) => (
              <CancelButton
                key={request.createdAt + request.amount}
                request={request}
              />
            ))}
      {pendingCashOuts.length > 2 && (
        <>
          <Button background="buttonPrimary" onClick={showMoreModal.onOpen}>
            +
          </Button>
          <Modal isOpen={showMoreModal.isOpen} onClose={showMoreModal.onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalBody paddingY="2rem" as={VStack} spacing="1rem">
                {pendingCashOuts?.map((request) => (
                  <CancelButton
                    key={request.createdAt + request.amount}
                    request={request}
                  />
                ))}
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
      <ConfirmModal isOpen={confirmModal.isOpen} onClose={confirmModal.onClose}>
        <ModalHeader>{t('common:cancelMyRequest')}</ModalHeader>
        <ModalBody fontWeight="bold">
          <Text fontWeight="bold" marginBottom="1rem">
            {t('common:yourRequestWillCancel')}
          </Text>
          <Text>IBAN: {currentRequest?.IBAN}</Text>
          <Text>
            {t('common:amount').toUpperCase()}:{' '}
            {FormattedAmount(currentRequest?.amount || 0)}
          </Text>
          <Text>
            {t('common:date').toUpperCase()}:{' '}
            {FormatDate(currentRequest?.createdAt || '')}
          </Text>
          <Button
            data-testid="confirm-cancel-request"
            backgroundColor="buttonPrimary"
            marginTop="1rem"
            onClick={confirmCancel}
            isLoading={loadingCancel}
          >
            {t('common:confirm')}
          </Button>
        </ModalBody>
      </ConfirmModal>
      <SuccessModal isOpen={successModal.isOpen} onClose={successModal.onClose}>
        <Text fontWeight="bold">{t('common:requestCancelled')}</Text>
        <Text>
          {t('common:amountAddedToWallet', {
            amount: FormattedAmount(currentRequest?.amount || 0)
          })}
        </Text>
      </SuccessModal>
    </VStack>
  );
};

export default CancelRequestedCashOut;
